import React from 'react';
import PropTypes from 'prop-types';
import './Week04.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';
import Illustration from '../lessonComponents/Illustration/Illustration';

const Week04 = () => (
  <div className="Week04" data-testid="Week04">
    <LessonHeader image="https://images.unsplash.com/photo-1467179257860-0b3be79206f8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1651&q=80"
                  week="4 НЕДЕЛЯ"
                  title="ИЗМЕНЯЯСЬ В ЕГО ОБРАЗ: СЛУЖЕНИЕ"/>

    <Title>БОЖЬЯ ЦЕЛЬ ДЛЯ НАС</Title>

    <Paragraph>
      Многие христиане считают, что духовный рост происходит, 
      когда человек узнает больше фактов о Боге. Но это только начало! 
      Бог не хочет, чтобы мы узнавали о Нем, как мы узнаем о математике или истории. 
      Он хочет, чтобы мы познали Его. Если мы наполняем свою голову знаниями и не 
      имеем отношений любви с Богом, мы ошибаемся и можем стать гордыми <b>(1Коринфянам 8:1)</b>.
    </Paragraph>

    <Question text="Бытие 1:26-27"/>
    <Paragraph>
      Иисус объяснил это фарисеям в <b>Евангелии от Матфея 22:15-22</b>. Он сказал, что, 
      поскольку на римских монетах изображен кесарь, монеты принадлежат императору. 
      И потому, как люди несут в себе образ Бога, они принадлежат Богу. 
      Но иногда, образ Божий настолько поврежден грехом, что становится трудно представить, 
      что он все еще находится там. Божий искупительный план и заключается в том, 
      чтобы восстановить человека и вернуть его в отношения с Собой. 
      Так как Иисус является совершенным примером того, каким должен быть человек 
      (а также, каким должен быть Всемогущий Бог), Он и есть образец в нашей жизни.
    </Paragraph>

    <Question text="Римлянам 8:29"/>
    <Question text="2 Коринфянам 3:18"/>

    <Paragraph>
      Каждый день множество вещей оказывает влияние на наши мотивы, цели, приоритеты и ценности. 
      Изменяет ли Господь вас в Свой образ?
    </Paragraph>

    <Title>ПОКАЗЫВАТЬ МИРУ ИИСУСА</Title>
    <Paragraph>
      Одна из причин, почему Господь изменяет нас в Свой образ, — чтобы мы смогли показать всему миру, 
      Кем Он является. Существует параллель между нашими жизнями и жизнью Иисуса. 
      Он показывал миру, Кем является Отец. Любой, кто хочет узнать характер Бога, 
      может просто посмотреть на Иисуса, и тогда он увидит Отца.
    </Paragraph>

    <Question text="Евреям 1:3"/>

    <Paragraph>
      Как Иисус показывал миру, кем является Отец, так и христиане имеют ответственность донести миру, 
      кем является Иисус. Писание подтверждает, что Бог возложил эту обязанность на нас.
    </Paragraph>

    <Question text="От Матфея 5:14"/>

    <Paragraph>
      Чем больше Бог работает в нашей жизни, тем больше мы показываем характер Иисуса. 
      Мир отчаянно нуждается в том, чтобы видеть живые примеры того, кто Он такой. 
      Также как и во времена Иисуса, люди сегодня ищут реальности Бога и пытаются найти Его.
    </Paragraph>

    <Paragraph>
      Неспасенный мир часто слышит об Иисусе, но не видит Его. 
      Господь уже не живет в человеческом теле. Мы – Его Тело здесь на земле. 
      Когда люди хотели увидеть Иисуса, они подходили к Его ученикам, и тогда те указывали им на Иисуса. 
      Люди по-прежнему хотят видеть Господа, и они подходят к Его сегодняшним ученикам. 
      А мы должны показывать Иисуса через наши собственные жизни.
    </Paragraph>

    <Paragraph>
      Когда Иисус жил на земле, Он ограничил Себя формой человека. 
      Бог жил в человеческом теле. Когда Он был в Капернауме, Его не было в Вифлееме. 
      Когда Он был в Иерусалиме, Его не было в Назарете. Бог находится везде <b>(Иеремия 23:23-24, Псалом 138:7-10)</b>, 
      но Его физическое присутствие было ограничено одним местом. Жизнь Иисуса выразила природу и характер 
      Бога по отношению к человечеству. Иисус сказал: «Лучше для вас, чтобы Я пошел» <b>(Иоанна 16:7)</b>. 
      Но как это может быть? Теперь, когда Он воскрес, вознесся на небеса и послал Духа Святого в церковь, 
      каждый ученик может показать природу и характер Бога. Люди в Буэнос- Айресе, Бостоне, Берлине и Пекине, 
      в одно и то же время, могут видеть живой пример Божьего присутствия, если рядом с ними живут христиане. 
      Насколько Отец сможет достичь Своей цели и преобразить нас в образ Иисуса, настолько мир сможет увидеть 
      Его. Многие люди никогда не читали Библии, но они читают наши жизни каждый день. 
      Верно ли мы показываем Иисуса этому миру?
    </Paragraph>

    <Question text="2 Коринфянам 3:2-3"/>

    <Title>КАК МЫ МОЖЕМ ДОСТИЧЬ СХОЖЕСТИ С ИИУСОМ?</Title>

    <Paragraph>Процесс преобразования включает в себя несколько основных элементов:</Paragraph>
    <Paragraph>
      <b>1. ДИСЦИПЛИНА.</b><br/>
      Это включает в себя как Божье исправление, так и нашу ответственность хранить свою жизнь 
      в соответствии Слову Божьему, что является самодисциплиной.
    </Paragraph>

    <Question text="Евреям 12:5-11"/>
    <Question text="1 Коринфянам 9:27"/>

    <Paragraph>
      Дисциплинировать себя означает учиться переносить трудности. 
      Никто не ищет трудных ситуаций, но такие обстоятельства заставляют нас полагаться на силу 
      Господа, и в результате, наша вера растет.
    </Paragraph>

    <Question text="2 Тимофея 2:3-7"/>

    <Paragraph>
      <b>2. СМЕРТЬ ДЛЯ СЕБЯ И ОТКАЗ ОТ ЭГОИЗМА.</b><br/>
      Из любви к Господу и из желания угодить Ему мы ставим Его волю выше нашей собственной. 
      Как Писание учит нас, и как Дух Святой ведет нас, мы делаем то, что не хотим делать, 
      и не делаем то, что хотим сделать! Это сопровождает всех учеников Иисуса.
    </Paragraph>

    <Question text="От Матфея 16:24-25"/>

    <Paragraph>
      Мы должны знать цену следования за Иисусом. Мы осознаем, что мятеж против Божьей воли лишает 
      нас близкого общения с Ним. Мы можем оскорбить Святого Духа, и тогда не сможем быть полезными Ему.
    </Paragraph>      

    <Question text="Ефесянам 4:30"/>
    <Question text="2 Тимофея 2:20-22"/>

    <Paragraph>
      <b>3. СТРАДАНИЯ.</b><br/>
      Нравится нам это или нет, но это обязательный предмет в учебном плане Бога. 
      Иисус показал нам пример, следуя этим путем до конца.
    </Paragraph>

    <Question text="Евреям 5:8"/>
    <Question text="1 Петра 2:21-24"/>

    <Paragraph>
      Слово «пример» на греческом буквально означает «копирование». 
      Подумайте о вашей жизни, как о чистом листе бумаги. Бесчисленные варианты доступны для вас, 
      когда вы идете по жизни. Вы можете нарисовать свой собственный сюжет или скопировать следы жизни Иисуса. 
      Бог призывает нас следовать этому образцу. Он не посылает испытания и страдания напрасно. 
      У Него есть Божественная цель для каждой проблемы, с которой мы сталкиваемся в жизни.
    </Paragraph>

    <Question text="Иакова 1:2-4"/>
    <Question text="1 Петра 4:12-14"/>
    <Question text="1 Петра 5:10"/>

    <Paragraph>
      Так часто мы хотим возрастать духовно и не платить цену за это. 
      Легкая и приятная жизнь не способна произвести на свет сильных учеников. Если мы хотим, 
      чтобы Бог сделал нас похожими на Иисуса, это не будет безболезненно.
    </Paragraph>

    <Title>ЦЕЛЬ УЧЕНИЧЕСТВА: СТАТЬ СЛУГОЙ</Title>
    <Paragraph>Истинное ученичество ведет только в одном направлении: становиться, как Иисус.</Paragraph>

    <Question text="1 Иоанна 4:17"/>

    <Paragraph>
      Время от времени, мы встречаем христиан, которые отличаются от всех остальных. 
      Они позволили кресту работать в их жизни, и через дисциплину, смерть для себя и страдания, 
      они показывают Иисуса в большей степени, чем мы. Даже находясь в их присутствии, мы чувствуем обличение, 
      потому что мы видим, что они живут на таком уровне послушания, святости и чистоты сердца, 
      о котором мы не знаем. Их искренность является постоянным свидетельством для этого мира. 
      А вы встречались с подобными людьми? Могли бы вы назвать некоторых из них? Хотите быть одним из них? 
      Как и Иисус, они – слуги. Мы не сможем быть похожи на Иисуса в качестве Спасителя и Искупителя, 
      но Бог призывает нас быть похожими на Него в качестве слуги.
    </Paragraph>

    <Question text="Исайя 53:11"/>
    <Question text="Филиппийцам 2:5-8"/>

    <Paragraph>
      Когда Господь меняет нас в Свой образ, мы становимся менее эгоистичными и хотим служить другим. 
      Это противоречит точке зрения этого мира. Большинство людей желают, чтобы служили им, 
      а не чтобы служить другим. Люди думают: «А что это мне даст? Как я смогу получить больше? 
      Как мне попасть на самый верх?». Мотивы этого мира — это получать, а мотивы Царства Божьего — отдавать. 
      Можете ли вы себе представить, насколько изменился бы мир, если бы все хотели давать? Тогда, 
      все также бы смогли и получать! Что если бы люди всегда задавали себе вопрос: 
      «Как я могу сделать чью-то жизнь лучше? Как я могу сделать других счастливыми?». 
      Жизнь бы в этом мире была совсем другой!
    </Paragraph>

    <Question text="Галатам 6:10"/>

    <Paragraph>
      Если вы хотите быть великим в Царстве Божьей, сложите ваши права и служите другим. 
      Если вы хотите быть большим, станьте рабом, у которого вообще нет никаких прав.
    </Paragraph>

    <Question text="От Матфея 20:25-28"/>
     
    <Paragraph>
      Лучшие судьи нашего служения — это люди, которые живут с нами. Они знают, кто мы на самом деле. 
      Если вы хотите знать, какой вы слуга, спросите вашу маму. Для тех, кто живет вдали от семьи, 
      спросите ваших соседей. Как много самопожертвования другие видят в вашей жизни, и как много эгоизма? 
      Божий призыв служить для каждого из нас не будет одинаковым. Но это будут ситуации, 
      в которых нам нужно будет положить свою жизнь для других. Иисус — это наш главный пример.
    </Paragraph>

    <Question text="Иоанна 15:12-13"/>

    <Paragraph>
      Если мы попытаемся сохранить нашу жизнь для себя, мы потеряем ее. 
      Это не означает физическую смерть. Многие христиане чувствуют, что после захватывающего 
      начала их христианской жизни, они застряли в скучной религиозности. Они пропускают приключения, 
      наступающие, когда человек живет в центре Божьей воли. Но они постепенно начинают ходить путями 
      этого мира и не обращаются, чтобы служить, и не живут по вере. Не отдавая свою жизнь, 
      они теряют жизнь, которую бы могли иметь в Иисусе. Не попадайтесь в эту ловушку!
    </Paragraph>

    <Title>НАГРАДА СЛУГИ</Title>

    <Paragraph>
      Приходит радость, когда мы делаем шаги веры, и становимся слугами Бога <b>(Луки 10:17)</b>. 
      Когда мы отдаем свою жизнь для Бога и других, мы получаем настоящую жизнь. 
      Нет более глубокого удовлетворения, чем быть в центре Божьей воли. 
      Это намного больше, чем просто быть счастливым. Счастье приходит от того, что с нами происходит. 
      Когда случаются хорошие вещи, мы счастливы, но когда плохие, тогда счастье оставляет нас. 
      Радость приходит от Божьего одобрения, и мы можем найти ее только в Его присутствии.
    </Paragraph>

    <Question text="Псалом 15:11"/>

    <Paragraph>
      Бог обещает вечную награду Своим слугам. Мы получим венцы, когда однажды предстанем перед Ним 
      <b>(1 Коринфянам 9:25, 2 Тим.4:8, Иакова 1:12, 1 Петра 5:4, Откровение 2:10)</b>. 
      Насколько верно мы служим Ему, настолько мы можем быть уверены, что выполняем Его цель для нашей жизни. 
      Он желает, чтобы мы показали Иисуса этому миру через служение, а также, в результате, Он хочет вознаградить 
      нас. Некоторые люди не служат и упускают свою награду на небесах. Если вы позволите Господу изменять вас 
      и станете Его слугой, вы получите свое вознаграждение. Послушный и верный получает корону.
    </Paragraph>

    <Paragraph>
      Для всех нас, кто любит Господа и служит Ему, в Писании содержится обещание: 
      «Но боящиеся Бога говорят друг другу: «внимает Господь и слышит это, и пред лицом 
      Его пишется памятная книга о боящихся Господа и чтущих имя Его». И они будут Моими, говорит Господь Саваоф, 
      собственностью Моею в тот день, в который Я соделаю, и буду миловать их, как милует человек сына своего, 
      служащего ему. И тогда снова увидите различие между праведником и нечестивым, между служащим Богу и не 
      служащим Ему». <b>(Малахия 3:16-18)</b>
    </Paragraph>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text="ПослеУрока1">Как люди видят Бога в вашей жизни?</Question>
    <Paragraph>Опишите, как Господь работает в вас через:</Paragraph>
    <Question text="ПослеУрока2">— Дисциплину</Question>
    <Question text="ПослеУрока3">— Самоотречение</Question>
    <Question text="ПослеУрока4">— Страдание</Question>
    <Question text="ПослеУрока5">Как вы следуете по следам Иисуса в вашей жизни (1 Петра 2:21-24)?</Question>
    <Question text="ПослеУрока6">Приведите пример того, как вы ставите волю Божью выше вашей собственной.</Question>

    <Title>ПРОВЕРОЧНЫЕ ВОПРОСЫ:</Title>
    <Question text="Проверочный1">Как Господь преображал вас в образ Иисуса на этой неделе?</Question>
    <Question text="Проверочный2">Каким образом на этой неделе вы показали Христа людям вокруг вас?</Question>
    <Question text="Проверочный3">Каким образом вы послужили своей семье на этой неделе? В школе или на работе?</Question>
    <Question text="Проверочный4">Вы получали радость от того, что вы послужили?</Question>
    <Question text="Проверочный5">Приходилось ли вам отставлять в сторону свои права и умирать для себя на этой неделе? Каким образом?</Question>
  </div>
);

Week04.propTypes = {};

Week04.defaultProps = {};

export default Week04;
