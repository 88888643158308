import React from 'react';
import './Week11.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';

const Week11 = () => (
  <div className="Week11" data-testid="Week11">
    <LessonHeader image="https://images.unsplash.com/reserve/tlC8KhNJR0CA2xdomLfx_DSC_0358-2.jpg?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1652&q=80"
                  week="11 НЕДЕЛЯ"
                  title="БЫТЬ ПРИМЕРОМ"/>

    <Paragraph>
      Самым большим обвинением в адрес христиан является осуждение их в лицимерии. 
      Это неправда в отношении подлинных верующих, но нехристиане часто используют это в качестве оправдания. 
      Они оценивают нас с помощью стандартов праведности (обычно верных), которых сами не придерживаются. 
      Они обвиняют нас в лицимерии, когда видят христиан, не похожих на Христа, и иногда, они бывают правы. 
      Это может оказаться разрушительным, не так ли?
    </Paragraph>

    <Title>ПРОБЛЕМА ЛИЦЕМЕРИЯ</Title>

    <Paragraph>
      Иисуса называли другом грешников <b>(Мф. 11:19)</b>. Он показал
      сострадание к сокрушенным и раскаивающимся, несмотря на то, какие ужасные грехи они совершали 
      <b>(Лк. 7:36-50)</b>, но Его самые резкие слова были для лицемеров.
    </Paragraph>

    <Question text="От Матфея 23:13"/>

    <Paragraph>
      Обычно, мы видим лицемерие в других, а не в себе. Пока сломленность и смирение от Бога не начнут влиять на нашу жизнь, 
      легко обмануться, осуждая других людей.
      Бог ненавидит лицимерие. Он желает реальности и подлинности в нашей жизни.
    </Paragraph>

    <Question text="Псалом 50:8"/>

    <Question texty="От Иоанна 14:17"/>

    <Paragraph>
      Правда угодна Богу, а подделки Он не терпит <b>(Втор. 25:13-16, Откр. 21:27)</b>. Он хочет, чтобы наша жизнь была той же, 
      как внутри, так и снаружи. Это похоже на мебель: она может быть полностью изготовлена из хорошего 
      дерева или сделана из дешевого материала покрытого тонким слоем ценных пород. Слишком много людей, 
      которые хорошо выглядят снаружи, но Бог знает их сердца. Сегодня так много дешевых христиан!
    </Paragraph>

    <Question text="От Матфея 23:27"/>

    <Paragraph>
      Лицемеры похожи на здания-подделки во времы съемок фильма. Спереди дом выглядит реальным, но, 
      если обойти его, можно увидеть плоскую стену, подпираемую сзади. Многие люди показывают только свой фасад, 
      чтобы впечатлить других, но трудно поддерживать ложное впечатление. Фасад, подпираемый ложью, нестабилен и 
      обычно, люди быстро узнают правду.
    </Paragraph>

    <Title>ПОСЛЕДСТВИЯ ЛИЦЕМЕРИЯ</Title>

    <Paragraph>
      Лицемерие – это камень предкновения для людей на пути к Иисусу. Мы не знаем последствий наших жизней пока мы на земле. 
      Мы можем оказывать позитивное или негативное влияние на людей, с которыми мы живем, работаем и учимся. 
      Последствия выбора этих людей служить Иисусу или отвергнуть Его, могут быть невероятными.
    </Paragraph>

    <Paragraph>
      Ганди проводил время с евангельскими христианами в Африке и Англии. Он слышал Евангелие и был знаком с христианством 
      до того, как вернулся в Индию и повел за собой огромное количество людей. Позже он написал: 
      «Я был бы убежден стать христианином, если бы не христиане». Другими словами, он поверил, что христианство могло быть 
      правдой, но он видел жизни христиан, которые не соответствовали той вере, которую они исповедовали. Он остался 
      в индуизме, и вся его нация осталась в темноте.
    </Paragraph>

    <Paragraph>
      Карл Маркс также слышал Евангелие. Жена его брата была христианкой, и он жил в Германии и Лондоне во времена пробуждения. 
      Трое детей Карла умерло от плохого питания, что привело его к чувству горечи по отношению к христианам. 
      Он смотрел на верующих, как на лицимеров, и, вместо принятия христианства, он разработал 
      гуманистическую теологию коммунизма. Два этих человека повлияли на жизни больше, чем половины населения земли. 
      Каким бы мир был, если бы Ганди вернулся в Индию, как верующий, и привел всю страну к Иисусу? 
      Или, если бы Маркс вложил все свои усилия в развитие Божьего Царства?
    </Paragraph>

    <Paragraph>
      Мы не знаем, кто наблюдает за нам сегодня, и как мы можем изменить этот мир, живя подлинной христианской 
      жизнью и показывая Божий свет людям вокруг нас.
    </Paragraph>

    <Title>СИЛА НАСТОЯЩЕГО ХРИСТИАНСТВА</Title>

    <Paragraph>
      Есть что-то чудесное в искренних христианах. Люди замечают, что они другие. Когда человек является 
      настоящим примером Господа, присутствие Бога обличает грешников и привлекает опусташенных и раненых. 
      Добрая жизнь верующего приносит славу Богу и молчание Его врагам.
    </Paragraph>

    <Question text="От Матфея 5:16"/>
    <Question text="К Титу 2:7-8"/>

    <Paragraph>
      Когда человек утверждает, что он христианин, но не живет, как последователь Христа, т
      огда он утверждает противоположное.
    </Paragraph>

    <Question text="К Титу 1:16"/>
    <Question text="Римлянам 2:23-24"/>

    <Paragraph>
      Если мы будем жить так, как верим, мы будем менять жизни и судьбы людей вокруг, потому что из нас будет истекать Божий Дух. 
      Многие христиане не понимают, как Дух Святой может изливаться из их жизни. Они думают, что Бог может 
      использовать только супердуховных христиан. Но у Бога нет любимчиков.
    </Paragraph>

    <Question text="Римлянам 2:11"/>

    <Paragraph>
      Господь будет использовать любого, кто готов по-настоящему подчинить свою волю Ему. Не получится жить и по плоти, и 
      по Духу. Если вы ходите в Духе Святом <b>(Галатам 5:25)</b>, вы превращаетесь в сосуд силы Божьей. 
      Живя небрежно и эгоистично и при этом ожидая, что быстрые молитвы сделают нас полезными Богу – 
      это злоупотребление благодатью. Никакая сила или помазание не достается дешево.
    </Paragraph>

    <Paragraph>
      В редких случаях, Господь может позволить Его помазанию течь через верующего, который отвернулся от Него или, 
      даже, через неверующего. Это может происходить, когда человек занимал какую- то должность или позицию, 
      предназначенную Господом. Царь Саул пророчествовал после того, как он впал в грех <b>(1 Царств 19:23-24)</b>. 
      Также, как и первосвященник Каиафа предсказал смерть Иисуса, а затем, участвовал в заговоре, чтобы распять 
      Его <b>(Ин. 11:49-53)</b>.
    </Paragraph>

    <Title>ЖИТЬ, КАК ИИСУС</Title>

    <Paragraph>
      Иногда, христиане говорят: «Не смотри на меня, смотри на Иисуса». Отчасти, это правда, ведь, люди не должны смотреть 
      на нас для решения своих проблем, а также, мы не должны получать славу, когда Бог использует нас. 
      Тем не менее, когда люди смотрят на нас, они должны видеть Иисуса.
    </Paragraph>

    <Question text="1 Иоанна 4:17"/>

    <Paragraph>
      Если люди, смотря на нас, не видят Иисуса, это значит, что мы не живем, «как Он». Апостол 
      Павел сделал поразительно множество заявлений о том, чтобы быть, «как Он». Давайте посмотрим на неоторые из них.
    </Paragraph>

    <Question text="Филиппийцам 4:9"/>

    <Paragraph>
      Обратите внимание, что Павел писал: «не только то, чему вы научились и слышали, но то, что вы видели во мне».
    </Paragraph>

    <Question text="1 Коринфянам 11:1"/>
    <Question text="1 Коринфянам 4:15-16"/>
    <Question text="Филиппийцам 3:17"/>

    <Paragraph>Павел был уверен, что его жизнь была примером всего, о чем он проповедовал.</Paragraph>

    <Question text="2 Тимофею 3:10-11"/>
    <Question text="1 Фессалоникийцам 1:5-6"/>
    <Question text="1 Фессалоникийцам 2:8, 10"/>

    <Paragraph>Павел утверждал, что, даже Бог был свидетелем подлинного примера его жизни.</Paragraph>

    <Question text="2 Фессалоникийцам 3:7, 9"/>

    <Paragraph>
      Бог дал нам духовных лидеров в качестве примера, и Он призывает нас следовать за ними. Если вы уважаете своего пастора 
      или молодежного лидера, вы будете учиться от них через их проповеди. Если ваш лидер является целостной личностью, 
      вы научитесь большему, наблюдая за его жизнью. Ищите возможности в повседневной жизни, чтобы провести время с вашим лидером. 
      Понаблюдайте, как он относится к другим: супругу(е), детям, его последователям, официанту в ресторане и ко всем, 
      с кем он вступает в контакт. Это расскажет вам больше о человеке, чем хорошо запланированный Библейский урок или проповедь. 
      Посмотрите, ищет ли ваш лидер возможности, чтобы поделиться Евангелием, терпилив ли он за рулем или в очереди, 
      рассказывает ли вульгарные анекдоты, служит другим, живет в смирении, признается в своих ошибках и почитает ли он 
      Бога во всех аспектах своей жизни. Это не предложение для вас собрать личное дело на ваших руководителей или ждать, 
      что они как-то опозорятся. Помните о том, что каждый из нас отстает во многих отношениях <b>(Иакова 3:1-2)</b>. 
      Скорее, это возможность увидеть, как наши отношения с Богом отражаются в реальной жизни.
    </Paragraph>

    <Question text="Евреям 6:12"/>
    <Question text="Евреям 13:17"/>

    <Paragraph>
      Мы являемся духовным примером для остальных. Наш благочестивый образ жизни может вдохновить тех, кто смотрит 
      на нас или наши неудачи могут привести к тому, что кто-то споткнется. Ответственность и благодать идут рука об руку, 
      когда мы стремимся быть, как Иисус в этом мире. Не думайте, что вы не сможете этого достичь. 
      Пусть Божья благодать изменит вашу жизнь, чтобы вы смогли помочь изменить этот мир. Вы можете быть непорочным и 
      праведным примером для подражания.
    </Paragraph>

    <Question text="Псалом 36:37"/>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text="ПослеУрока1">Приведите пример лицемерия, свидетелем которого вы были. Простили ли вы этого человека, чье поведение огорчило вас?</Question>
    <Question text="ПослеУрока2">Осознавали ли вы раньше силу влияния вашего примера на других людей?</Question>
    <Question text="ПослеУрока3">Можете ли вы сказать то же самое, что Павел сказал в Послании к Филиппийцам 4:9? Почему «да» или почему «нет»?</Question>

    <Title>ВОПРОСЫ В КОНЦЕ НЕДЕЛИ:</Title>
    <Question text="Проверочный1">Кто наблюдал за вашей жизнью на этой неделе?</Question>
    <Question text="Проверочный2">Вдохновил ли ваш пример кого-то на этой неделе жить для Господа?</Question>
    <Question text="Проверочный3">
      Назовите кого-то, с кем вы регулярно видетесь, на кого вы могли бы повлиять? 
      Каким образом вы можете быть примером для этого человека?
    </Question>
  </div>
);

Week11.propTypes = {};

Week11.defaultProps = {};

export default Week11;
