import React, { useState, useEffect } from 'react';
import './Plan.scss';
import data from './data/BiblePlan.json';

import { useLocation } from 'react-router-dom';
import 'firebase/firestore';
import {firestore, auth} from '../../../firebase/firebaseConfig';

import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import {Check2Square, Square} from "react-bootstrap-icons";

function Plan (){ 
  const { uid } = auth.currentUser;
  const [planProgress, setPlanPorgress] = useState([false, false, false, false, false, false, false]);
  let id = useLocation().pathname;
  let weekNum = parseInt(id.substr(id.length - 2), 10);

  

  useEffect(() => {
    const docRef = firestore.collection(id.substr(id.length - 6)).doc(uid).collection('biblePlan').doc('progress');
    docRef.get().then((doc) => {
      if (doc.exists) {
          let data = doc.data();
          setPlanPorgress(data["planProgress"]);
      }
  }).catch(function (error) {
      console.log("Error getting document:", error);
  });
  }, [])

  const toggleChange = (dayIndex) => async (e) => {
    let tmp = [...planProgress];
    tmp[dayIndex] = !tmp[dayIndex];
    setPlanPorgress(tmp);

    await firestore.collection(id.substr(id.length - 6)).doc(uid).collection('biblePlan').doc('progress').set({"planProgress":
      [tmp[0], tmp[1], tmp[2], tmp[3], tmp[4], tmp[5], tmp[6]]
    });
  }

  return(
    <div className="Plan" data-testid="Plan">
      <Container className="containerList">
        <h2 className="listItem">План и Молитва:</h2>
        <ListGroup>
          
          <ListGroup.Item  className="listItem" onClick={toggleChange(0) } variant={planProgress[0] ? 'success' : ''}>
              <div className="dayName">
                <Check2Square className={planProgress[0] ? 'active' : 'nonactive'}/>
                <Square className={!planProgress[0] ? 'active' : 'nonactive'}/>
                &nbsp;
                Понедельник:
              </div>
              <b>{data[weekNum - 1]["mon"]}</b>
          </ListGroup.Item>

          <ListGroup.Item className="listItem" onClick={toggleChange(1) } variant={planProgress[1] ? 'success' : ''}>
            <div className="dayName">
              <Check2Square className={planProgress[1] ? 'active' : 'nonactive'}/>
              <Square className={!planProgress[1] ? 'active' : 'nonactive'}/>
              &nbsp;
              Вторик:
            </div> 
            <b>{data[weekNum - 1]["tue"]}</b>
          </ListGroup.Item>

          <ListGroup.Item className="listItem" onClick={toggleChange(2) } variant={planProgress[2] ? 'success' : ''}>
            <div className="dayName">
              <Check2Square className={planProgress[2] ? 'active' : 'nonactive'}/>
              <Square className={!planProgress[2] ? 'active' : 'nonactive'}/>
               &nbsp;
              Среда:
            </div> 
            <b>{data[weekNum - 1]["wed"]}</b>
          </ListGroup.Item>

          <ListGroup.Item className="listItem" onClick={toggleChange(3) } variant={planProgress[3] ? 'success' : ''}>
            <div className="dayName">
              <Check2Square className={planProgress[3] ? 'active' : 'nonactive'}/>
              <Square className={!planProgress[3] ? 'active' : 'nonactive'}/>
              &nbsp;Четверг:
            </div> 
            <b>{data[weekNum - 1]["thu"]}</b>
          </ListGroup.Item>

          <ListGroup.Item className="listItem" onClick={toggleChange(4) } variant={planProgress[4] ? 'success' : ''}>
            <div className="dayName">
              <Check2Square className={planProgress[4] ? 'active' : 'nonactive'}/>
              <Square className={!planProgress[4] ? 'active' : 'nonactive'}/>
              &nbsp;Пятница:
            </div> 
            <b>{data[weekNum - 1]["fri"]}</b>
          </ListGroup.Item>

          <ListGroup.Item className="listItem" onClick={toggleChange(5) } variant={planProgress[5] ? 'success' : ''}>
            <div className="dayName">
              <Check2Square className={planProgress[5] ? 'active' : 'nonactive'}/>
              <Square className={!planProgress[5] ? 'active' : 'nonactive'}/>
              &nbsp;Суббота:
            </div> 
            <b>{data[weekNum - 1]["sat"]}</b>
          </ListGroup.Item>

          <ListGroup.Item className="listItem" onClick={toggleChange(6) } variant={planProgress[6] ? 'success' : ''}>
            <div className="dayName">
              <Check2Square className={planProgress[6] ? 'active' : 'nonactive'}/>
              <Square className={!planProgress[6] ? 'active' : 'nonactive'}/>
              &nbsp;Воскресенье:
            </div> 
            <b>{data[weekNum - 1]["sun"]}</b>
          </ListGroup.Item>
        </ListGroup>
        <br/>

        <div>
          <h4 className="listItem">{data[weekNum - 1]["learn1"]["place"]}</h4>
          <p className="listItem">
            <i>
            {data[weekNum - 1]["learn1"]["text"]}
            </i>
          </p>
        </div>

        <div>
          <h4 className="listItem">{data[weekNum - 1]["learn2"]["place"]}</h4>
          <p className="listItem">
            <i>
            {data[weekNum - 1]["learn2"]["text"]}
            </i>
          </p>
        </div>

        <br/>
      </Container>
    </div>
  )
}

Plan.propTypes = {};

Plan.defaultProps = {};

export default Plan;
