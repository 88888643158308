import React from 'react';
import './Week09.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';

const Week09 = () => (
  <div className="Week09" data-testid="Week09">
    <LessonHeader image="https://images.unsplash.com/photo-1461263895214-7761d3a942de?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1684&q=80"
                  week="9 НЕДЕЛЯ"
                  title="ПОБЕДА НАД ГРЕХОМ"/>

    <Paragraph>
      Многие христиане знают, что они любят Бога, но, несмотря на это, они постоянно сражаются с грехом. 
      Разные люди имеют разные слабости, и они продолжают спотыкаться на одном и том же месте. 
      Господь может избавить нас от этого и научить, как жить побеждая.
    </Paragraph>

    <Title>ЧТО ТАКОЕ ГРЕХ?</Title>

    <Paragraph>
      Буквальный перевод слова «грех» с греческого означает «промах», что иллюстрируется 
      примером со стрелой, которая не попала в цель. Если наша цель – угодить Господу, тогда нам нужно научиться 
      побеждать грех. Грех нарушает законы Бога. Это происходит, когда мы делаем то, что Бог запрещает, 
      а также, когда мы бездействуем в том, о чем Он нас просит.
    </Paragraph>

    <Question text="Римлянам 14:23(2):"/>
    <Question text="Иакова 4:17"/>

    <Paragraph>
      В основе греха лежит эгоизм, который не имеет оправдания в любой ситуации. Если мы представим 
      себе грех в виде дерева, тогда эгоизм является его корнями и стволом. Ветви и листья на дереве – 
      это бесчисленные вариации упрямства и бунта.
    </Paragraph>

    <Title>ПРОБЛЕМА ПОСТОЯННОГО ГРЕХА</Title>
    
    <Paragraph>
      Христиане, которые отчаянно борются за победу над грехом, сильно отличаются от тех, кто, называя 
      себя верующими, играют в игры с Богом. Первая группа людей является истинными детьми Божьими, попавшими 
      в ловушку. Они могут плакать и молиться за свободу, которую никак не могут найти. Они изо всех сил пытаются 
      измениться, но у них ничего не выходит. Им становится страшно читать Библию, в которой то и дело встречаются места 
      о Божьем суде и призыве к святости. Побежденные христиане не могут найти радость и мир, обещанные Богом.
    </Paragraph>

    <Question text="Притчи 28:13"/>

    <Paragraph>
      Идея оскорбления и неугождения Богу не волнует вторую группу людей, потому что для них 
      важно только то, что они попадут на небеса. Только Бог может видеть их сердца, но я убежден, 
      что такого рода «верующие» никогда не встречались с Иисусом.
    </Paragraph>

    <Question text="1 Иоанна 3:6, 8">1 Иоанна 3:6, 8 (заметьте, что греческий глагол здесь означает «постоянное согрешение»)</Question>
    <Question text="Галатам 6:7-8"/>
    <Question text="К Титу 1:16"/>

    <Title>КАК НАМ РАЗБИРАТЬСЯ С ГРЕХОМ?</Title>

    <Paragraph>
      Многие христиане пытаются разобраться только лишь с симптомами греха. Они думают, 
      что нужно больше работать над теми сферами их жизни, в которых они грешат. 
      Но это похоже на обрезку ветвей на деревьях. Само дерево от этого станет еще сильнее, а другие 
      ветви быстро заменят срезаные. Пытаться изо всех сил удовлетворить Божьи требования — это не путь к победе.
    </Paragraph>

    <Question text="1 Коринфянам 15:56(2)"/>

    <Paragraph>
      Господь использовал законы Ветхого Завета в качестве зеркала, чтобы указать людям на их вину. 
      Бог создал чувство вины, как результат знания закона, чтобы мы смиренно пришли к Иисусу за прощением. 
      Предназначением закона никогда не было сделать нас достаточно хорошими, чтобы Бог смог нас принять. 
      Только благодать, которая является незаслуженной милостью Бога, может спасти нас.
    </Paragraph>

    <Question text="Ефесянам 2:8-9"/>
    <Question text="Галатам 2:16"/>
    <Question text="Галатам 3:19,24"/>

    <Paragraph>
      Способ борьбы с грехом — это не пытаться совершенствовать самих себя, а рубить корень. Это покаяние в самом грехе.
    </Paragraph>

    <Paragraph>
      Бог видел, что грех настолько испортил жизнь даже «хорошего» человека, что нет никакой 
      возможности это исправить. Эгоизмом заражен каждый кусочек нашей жизни.
    </Paragraph>

    <Question text="Римлянам 3:10-18"/>
    <Question text="Иеремия 30:15"/>

    <Paragraph>
      Нашей безнадежной борьбы со слабостями было недостаточно, чтобы преодолеть зло человечества. 
      Вместо того, чтобы отремонтировать нашу греховную жизнь, Бог дал нам абсолютно новую жизнь. 
      Это не улучшение, это замена!
    </Paragraph>

    <Question text="2 Коринфянам 5:17"/>
    
    <Paragraph>
      Этот стих прямо говорит, что мы начинаем новый вид людей. Библия называет нас новым человеком, 
      а наша жизнь до Иисуса – это ветхий человек.
    </Paragraph>

    <Question text="Ефесянам 4:22-24"/>
    <Question text="Колоссянам 3:9-10"/>

    <Paragraph>
      Бог говорит нам, чтобы мы сняли с себя ветхого человека и оделись в нового, точно также, как 
      мы переодеваем нашу одежду! Это очень отличается от представления многих, как нужно побеждать грех. 
      Пытаться быть лучшим христианином – это, как зашивание, стирка и глажка уродливой, рваной и старой одежды. 
      И не важно, как много усилий вы прикладываете, вы никогда не получите то, на что надеятесь. 
      Разве не лучше получить новую одежду? Если бы вы получили новую одежду как подарок, неужели бы вы не переоделись сразу?
    </Paragraph>

    <Title>МЕРТВЕЦЫ НЕ ГРЕШАТ</Title>

    <Paragraph>
      Бог меняет нашу старую жизнь на новую через то, что Иисус совершил на кресте. 
      Он сделал больше, чем просто заплатил за наши грехи. Он разрушил силу греха, которая управляла нашими жизнями. 
      Наша греховность была пригвождена ко кресту вместе с Ним.
    </Paragraph>

    <Question text="Римлянам 6:6-7"/>
    <Question text="Галатам 5:24"/>
    <Question text="Галатам 6:14"/>

    <Paragraph>
      Если бы Иисус, умерев за наши грехи, не лишил грех силы в наших жизнях, тогда мы бы снова возвращались 
      в свое болото. Через крест Христос удалил то, что порождает грех – нашу старую природу, нашего ветхого человека.
    </Paragraph>

    <Paragraph>
      Сейчас ветхий человек мертв. Иногда мы этого не видим, мы этого не чувствуем, но Библия говорит, 
      что это так. Грех же не умер, он живет и процветает по всему миру. Наша греховная природа мертва, 
      и ее место сейчас заменила природа Бога. Заповеди в Библии предстают перед нами в другом свете, 
      когда мы понимаем, что в нашей новой природе есть желание подчиняться им.
    </Paragraph>

    <Title>КАК ПОБЕДИТЬ В ИСКУШЕНИИ</Title>

    <Paragraph>
      Кто-то может сказать: «Но у меня столько искушений, и я так часто поддаюсь соблазну!» 
      Вот ключ, чтобы выбраться из этого бесконечного круга.
    </Paragraph>

    <Question text="Римлянам 6:11-12"/>

    <Paragraph>Наш взгляд на жизнь имеет огромное влияние.</Paragraph>

    <Question text="Притчи 23:7(1)"/>

    <Paragraph>
      У нас есть выбор: позволить греху управлять нами или считать себя мертвыми для греха. 
      Вот как это работает. Мы все каждый день встречаемся с разными искушениями. 
      Соблазны не являются признаком нашей греховности, ведь даже Иисус был искушаем, но Он ни разу не согрешил.
    </Paragraph>

    <Question text="От Матфея 4:1"/>
    <Question text="Евреям 4:15-16"/>

    <Paragraph>
      Много раз мы чувствовали себя виноватыми за то, что видели соблазны вокруг нас, 
      но искушение превращается в грех только тогда, когда мы соглашаемся с ним через нашу волю. 
      Стратегия сатаны в том, чтобы выстрелить в нас стрелой искушения, а потом внушить нам чувство вины. 
      Когда мы осознаем, что соблазн – это атака врага, а не то, что мы – плохие люди, тогда мы обретаем гораздо 
      больше свободы и мира.
    </Paragraph>

    <Question text="1 Иоанна 3:21"/>

    <Paragraph>
      Искушение — это не признак слабости или отсутствия посвящения Господу. 
      Также, как Павел поступил со змеей, напавшей на него на Мальте <b>(Деян.28:3-5)</b>, так и нам нужно 
      поступать с соблазнами, просто стряхнуть их с себя!
    </Paragraph>

    <Paragraph>
      У нас нет причин раскаиваться в том, что мы искушаемся. Мы не должны осуждать себя 
      за соблазны, которые приходят к нам. Вот как нужно реагировать во время искушений: 
      всегда вспоминайте, что вы – мертвый человек. Мертвецы не грешат! Можно оскорблять их, 
      они никогда не обидятся. Можно ударить их, не беспокоясь, что они ударят в ответ. 
      Относитесь к себе, как к мертвому для греха. Запоминайте наизусть и повторяйте (даже вслух) 
      те места Писания, которые ясно говорят об этом. Говорите себе и дьяволу: «Я в прошлом хотел бы это сделать, 
      но мое старое «я» умерло на кресте с Иисусом 2000 лет назад. Я сейчас — новое творение, и я 
      свободен от власти греха. Я выбираю служить Господу!»
    </Paragraph>

    <Paragraph>
      Наш постоянный выбор подчиняться Господу разрушает привычку греха. 
      Ваша победа будет возрастать, когда вы поймете, что во Христе Иисусе вы — новое творение. 
      Как написано в следующем стихе, мы можем выбирать делать угодное Господу.
    </Paragraph>

    <Question text="Исайя 56:4-5"/>

    <Paragraph>
      Вот он, ключ к победе над грехом — принятие верой то, что Иисус сделал за нас на кресте. 
      Бог дал нам свободу выбирать пути, и Он будет помогать нам выбрать верный путь.
    </Paragraph>

    <Question text="Филиппийцам 2:13"/>

    <Paragraph>
      Обратите внимание, этот стих показывает, что Бог работает в двух направлениях: желание и действие. 
      Как только мы выбираем следовать за Ним, Он дает достаточно благодати, чтобы мы могли это осуществить. 
      Христианин, посвятивший себя Божьей воле, найдет победу в принципах, описанных выше. 
      Давайте не будем обманываться в том, что мы обречены совершать какой-то грех. Причина, 
      по которой некоторые соблазны так сильны и постоянны, в том, что мы действительно желаем их. 
      Пытаемся ли мы показаться Господу борющимися с искушением, при этом зная, что сдадимся вконце? 
      Так ли это? Если у вас есть проблемы с грехом, сможете ли вы честно задать Богу вопрос, 
      играете ли вы в игры с благодатью?
    </Paragraph>

    <Question text="1 Коринфянам 10:13"/>
    <Question text="2 Петра 2:9"/>

    <Title>ГРЕХ, ОРГАНИЗОВАННАЯ ПРЕСТУПНОСТЬ И ВЫ</Title>
    
    <Paragraph>
      Мы подходим к концу нашего урока с иллюстрациями о новой и старой жизни. Скорее всего вы слышали или видели, 
      каким образом правительство разбирается с преступностью. Когда они поймают преступника, который является частью 
      преступной группировки, они могут заключить с ним сделку. Это значит, что он должен рассказать все, что он знает о 
      других преступниках в обмен на свободу или более короткий срок заключения. Это помогает в преследовании 
      наиболее опасных правонарушителей.
    </Paragraph>

    <Paragraph>
      Но проблема в том, что сдавая других, информирующий ставит себя под угрозу со стороны криминального мира. 
      В США существует программа, назваемая «Федеральной защитой свидетелей». Так, бывший уголовник, помогающий 
      следствию и оказавшийся в опасности, получает от правительства новый дом, машину и работу в другом штате. 
      Правительство, также, предоставляет ему новое удостоверение личности, новое имя. Таким образом, старый 
      человек исчезает навсегда, а иначе, его бы смогли найти и убить. Человек должен абсолютно изменить свою личность, 
      чтобы быть в безопасности от старой жизни. Ему не нужно выдумывать ничего самому. Ему не говорили: «Постарайся 
      сделать все, что ты можешь». Но частью сделки было перейти из преступности на сторону закона и порядка. 
      Он получил не только прощение, но и новую жизнь, потому что он сменил сторону. Но помилование не дает 
      ему свободу вернуться к своему окружению, покинуть тюрьму и взяться за старое!
    </Paragraph>

    <Paragraph>
      Задачей человека является жизнь абсолютно новой личности в новых условиях, которые были созданы государством. 
      Он не может делать это наполовину, подписываясь своей старой росписью, откликаясь на свое старое имя, бывая там, 
      где он раньше обычно проводил время. Любая из этих вещей может подвергнуть его опасности. 
      Он не может играть свою роль, как будто он актер. Актер, возвращаясь домой после спектакля, 
      забывает про свою роль и становится самим собой. Бывший преступник должен стать новым человеком. 
      Он должен оставить все, что ассоциируется у него с прежней жизнью и жить новым человеком до конца своих дней.
    </Paragraph>

    <Paragraph>
      После того, как человек получает прощение и новое удостоверение личности, он должен быть очень благодарен. 
      Правительство простило его прошлое, но если он по глупости продолжит
      совершать преступления, он будет страдать от последствий. У него нет иммунитета от последствий преступлений в 
      будущем. Он не должен соединять свою старую жизнь с новой. Теперь, когда он новый человек, он начинает все 
      с чистого листа. У него новое начало, он – новая личность, и, кроме этого, он имеет поддержку самой влиятельной 
      структуры в стране – правительства.
    </Paragraph>

    <Paragraph>
      Вы видите в этом применение к своей собственной жизни? Как бывший преступник с новым удостоверением личности, 
      не возвращайтесь к прежней жизни! Учитесь не реагировать, когда враг называет вас по старому имени. Поймите, 
      что это больше НЕ ВЫ! Проживите всю вашу оставшуюся жизнь как новый человек с поддержкой Господа вашего Иисуса Христа, 
      помогающего вам. Его благодати и силы достаточно для того, чтобы жить, угождая Ему.
    </Paragraph>

    <Question text="Колоссянам 3:3-4"/>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text="ПослеУрока1">
      Подумайте о главной сфере вашей жизни, в которой вы сейчас сражаетесь с грехом. Видите ли вы корень этого греха в эгоизме?
    </Question>
    <Question text="ПослеУрока2">
      Пытаетесь ли вы разбираться с симптомами греха или с причиной?
    </Question>
    <Question text="ПослеУрока3">
      Что означает для вас быть новым творением во Христе Иисусе?
    </Question>
    <Question text="ПослеУрока4">
      Чувствовали ли вы когда-либо вину за то, что были искушаемы? Что этот урок помог вам понять?
    </Question>

    <Title>ВОПРОСЫ В КОНЦЕ НЕДЕЛИ:</Title>
    <Question text="Проверочный1">
      Думали ли вы о вашей жизни по-другому на этой неделе, понимая, что вы мертвы для греха? 
    </Question>
    <Question text="Проверочный2">
      Стало ли вам легче побеждать грех на этой неделе? 
    </Question>
    <Question text="Проверочный3">
      Какое место Писания больше всего помогает вам понять, как победить грех?
    </Question>
  </div>
);

Week09.propTypes = {};

Week09.defaultProps = {};

export default Week09;
