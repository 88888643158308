import React from 'react';
import './Week07.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';

const Week07 = () => (
  <div className="Week07" data-testid="Week07">
    <LessonHeader image="https://images.unsplash.com/photo-1452475168325-672188dc4dc3?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1653&q=80"
                  week="7 НЕДЕЛЯ"
                  title="ВЕРА И ВЕРНОСТЬ"/>

    <Paragraph>
      На этом уроке мы поговорим о вере и верности. Под верностью мы подразумеваем постоянство в следовании 
      Божьим планам для нашей жизни. Это наш ответ на Его призыв, наше с вами обещание Ему. 
      Каждый из нас имеет особое призвание от Бога. То, что Он желает сделать через наши жизни, повлияет на мир. 
      А ты уже знаешь свое призвание?
    </Paragraph>

    <Title>ПАДАЮЩИЕ ЗВЕЗДЫ В СРАВНЕНИИ С ГОРЯЩИМИ КУСТАМИ</Title>

    <Paragraph>
      Многие верующие похожи на падающие звезды или фейерверки — краткий блеск славы, 
      а затем их уже нет. У них все хорошо получается 3 недели, 2 месяца или год, а потом, 
      они выдыхаются в их христианской жизни. Даже неверующие могут какое-то время казаться христианами, 
      потому что плоть может выглядеть очень религиозной. Они самодовольно уверены в своей правоте, 
      чувствуют превосходство над другими, ожидают, что Бог им что- то должен, ждут похвалы от людей <b>(Ин. 5:41, 44)</b>, 
      не говоря уже о милых девушках и симпатичных парнях на молодежной группе!
    </Paragraph>

    <Paragraph>
      Бог видит очень много “добра”, но очень мало верности в мире <b>(Притчи 20:6)</b>. 
      Слишком многие христиане считают, что их страсть к Богу сгорела. Мы хотим помочь вам стать христианами, 
      похожими на горящие кусты. Помните, как Моисей увидел горящий куст, который не мог сгореть? 
      Куст не сгорал из-за присутствия Господа, а не потому что его ветви поддерживали огонь.
    </Paragraph>

    <Question text="Исход 3:2"/>

    <Paragraph>
      Куст мог гореть вечно, потому что источник огня был вечен. Таким же образом, 
      Бог может сохранить нас от духовного сгорания, если мы научимся полагаться на Его силу.
    </Paragraph>

    <Title>РОЛЬ ВЕРЫ</Title>

    <Paragraph>
      Без веры невозможно угодить Богу <b>(Евр. 11:6)</b>. Вера — это основа нашей христианской жизни. 
      Это доверие как записанному Божьему Слову Библии, так и Божьему Слову, сказанному в наши сердца. 
      Вера — это уверенность в том, что все, что Господь говорит, Он исполнит в соответствии с 
      Его природой и характером.
    </Paragraph>

    <Paragraph>
      Вере необходимо быть верной. Мы должны быть убеждены в том, что следуем и выполняем волю Бога. 
      Если нашим мотивом является глубокое внутреннее желание угодить Ему, тогда мы не будем искать легких путей, 
      но посвятим свою жизнь Его планам. Старание нашей плоти ни к чему не приведет!
    </Paragraph>

    <Paragraph>
      Божий путь не из легких. На нем мы встретим много препятствий, которые будут испытывать нашу веру. 
      Наша плоть ленива, упряма и мятежна. Этот мир считает нас сумасшедшими. Дьявол делает все возможное, 
      чтобы отвлечь нас, разочаровать и уничтожить. Даже добропорядочные христиане могут не понимать нас. 
      Иногда молодые христиане, услышав от Бога направление, могут обнаружить, что их родители, даже если они верующие,
       могут не поддержать их. Это препятствия, которые необходимо преодолеть, не позволяйте себе бунтовать! 
       Это также испытание вашей веры и верности в исполнении Божьей воли. Господь может изменить сердца родителей 
       и подтвердить Свое направление через них или использовать их, для того, чтобы исправить вас и вернуть на 
       верный путь. Ключ в том, с каким отношением сердца вы встречаете подобные обстоятельства. Вы обычно смиренные и 
       открытые, чтобы учиться, или вы самоуверенные и гордые? Вы послушные или упрямые?
    </Paragraph>

    <Paragraph>
      Жизнь по вере похожа на бег на длинные дистанции, а верность — это наша выносливость. 
      Такая жизнь не легка, но вознаграждение за нее будет выдающимся. Верность приводит в 
      исполнение Божьи обещания.
    </Paragraph>

    <Question text="Евреям 10:35-39"/>

    <Paragraph>
      Наше постоянство в вере и послушание позволит нам увидеть, как исполняются мечты и цели, которые Бог вложил в наши сердца.
    </Paragraph>

    <Question text="Екклесиаст 5:3"/>

    <Paragraph>
      Порой во время бега человек чувствует, что не может больше сделать и шагу. Нет больше сил, он исчерпал себя. 
      Спортсмены называют этот этап “ударом в стену”, и многие тогда сдаются. Но другие продолжают идти. 
      Каким-то образом, они находят в себе силы продвигаться вперед, после чего обретают второе дыхание. 
      Энергия возвращается к ним, и они завершают гонку. Христиане также бьются в стену, и многие отступают. 
      В нашей жизни, это может произойти в личных отношениях с Богом или в нашем призвании служить Ему. 
      В такие моменты, мы можем полагаться на Его силу, чтобы продолжать идти дальше. Однажды мы достигнем 
      финишной прямой на небесах.
    </Paragraph>

    <Question text="2 Тимофею 4:7"/>

    <Title>ПРЕПЯТСТВИЯ НА ПУТИ ВЕРНОСТИ</Title>

    <Paragraph>
      Мы встречаем множество препятствий на пути к плодотворной христианской жизни. 
      С Божьей помощью, мы сможем их преодолеть.
    </Paragraph>

    <Question text="Римлянам 8:37"/>
    <Question text="2 Коринфянам 2:14"/>

    <Paragraph>
      <b>Желание легкой жизни.</b> Легко исполнять Божью волю, пока все идет гладко. Если мы знаем, 
      что находимся на верном пути, и нас ждет немедленный успех, мы будем идти дальше. 
      Но тогда мы становимся духовно дряблыми и слабыми. Трудные времена создают крепких верующих. 
      Мой друг, однажды, сказал: “Христианам нужно научиться принимать удары”. Солдаты не проходят 
      свою тренировку в Диснейленде, но они тренируются там, где условия намеренно жесткие.
    </Paragraph>

    <Paragraph>
      В Библии Бог называет нас разными именами. Мы — Его дети, ученики, слуги и друзья. 
      Давайте, не будем забывать, что Он также называет нас солдатами. Терпели ли вы какие-либо лишения ради Евангелия?
    </Paragraph>

    <Question text="2 Тимофею 2:3-4"/>

    <Paragraph>
      <b>Желание получить земную награду.</b> Исполнять Божью волю в нашей личной жизни и в призвании не легко. 
      Когда становится трудно, нам нужно больше, чем получение удовлетворения здесь и сейчас. 
      Большинство плодов верности мы увидим только на небесах.
    </Paragraph>

    <Question text="Евреям 11:24-27"/>
    <Paragraph>
      Бог обещал вечную награду тем, кто повинуется Ему <b>(Откровение 2:7, 11, 17, 26; 3:5, 12, 21)</b>. 
      Но этих обещаний может быть недостаточно, чтобы подталкивать нас идти тогда, когда хочется отступить. 
      Нам нужно больше, чем обещания особняков на небесах или золотых улиц в тот момент, когда все наши чувства кричат: 
      “Сдаюсь!” Нам необходимо, чтобы глубокое внутреннее желание угодить Господу и любовь к Нему подпитывали нашу веру. 
      Все великолепие небес меркнет перед тем, когда мы увидем Его лицо и услышим Его слова: “Хорошо, добрый и 
      верный раб, войди в радость Господина твоего”. Заметьте, что Писание говорит о Боге, что Он – 
      ваш Господин <b>(Мф. 25:23)</b>. Когда люди, верно служащие Иисусу, попадут на небеса, им будет все равно, 
      сделаны дороги из золота или из чего-то другого. Они не будут переживать об особняках. Если их Господь будет там, 
      это все, что им нужно. Они будут жить с Ним вечно, зная, что Он доволен результатом их жизни, и в этом 
      они навсегда обретут полный мир и радость.
    </Paragraph>

    <Paragraph>
      <b>Главный удар сатаны – отчаяние.</b> Если противник заставит нас смотреть на обстоятельства, 
      а не на Господа, мы будем тонуть, как Петр, когда он ходил по воде <b>(Мф. 14:28-32)</b>. Потому что, когда 
      Иисус сказал Петру идти, он вначале послушался и сделал то, что по человеческим меркам невозможно. 
      Но когда Петр отвел свои глаза от Иисуса, из-за проблем, которые он увидел, его вера рухнула, и Господу 
      пришлось прийти ему на помощь. Разве не здорово знать, что даже если мы потерпим неудачу, Бог милостив, и 
      Он поможет нам! Если вы шагаете с верой в, казалось бы, невозможное, потому что Господь призвал вас туда, 
      ожидайте препятствия и штормы, ведь враг будет пытаться отвести ваш взгляд от Иисуса. Бог является источником 
      вашего вдохновения в такие времена. Бог не спускает с вас глаз, и вы должны не спускать глаз с Него.
    </Paragraph>

    <Question text="2 Паралипоменон 16:9"/>

    <Paragraph>
      Также, как и в раю, змей до сих пор пытается заставить нас сомневаться в том, действительно ли 
      Бог может говорить с нами, и говорит ли Он правду <b>(Быт. 3:1-5)</b>. Сатана использует наши мысли и замечания 
      других, чтобы заставить нас поверить в то, что все, что мы делаем – это пустая трата времени, и 
      Богу это не интересно. На его вопрос: «Неужели Бог тебе это сказал?», ответом веры будет: 
      «Бог сказал это, и я в это верю!».
    </Paragraph>

    <Paragraph>
      Чтобы совершить «подвиг веры» <b>(1 Тим. 6:12)</b>, вашим оружием должно стать Слово Божье. Стоит подчеркивать 
      и запоминать стихи из Библии, чтобы быть готовыми к сражениям, с которыми вы встретитесь. 
      У меня были трудные времена, когда я цитировал сам себе места Писания каждый день. И знает что? Слово 
      Божье действительно приносит ободрение!
    </Paragraph>

    <Question text="1 Коринфянам 15:58"/>
    <Question text="Галатам 6:9"/>
    <Question text="Псалом 36:4-7"/>

    <Paragraph>Также, прочитайте <b>Псалом 41 и 42</b>.</Paragraph>

    <Title>ТРИ ВИДА ВЕРНОСТИ</Title>

    <Paragraph>
      Отыщи три вида верности в Евангелии от <b>Луки 16:10-12</b>: «Верный в малом и во многом верен, а неверный 
      в малом неверен и во многом. Итак, если вы в неправедном богатстве не были верны, кто доверит вам истинное? 
      И если в чужом не были верны, кто даст вам ваше?»
    </Paragraph>

    <Paragraph>
      <b>Верность в малом.</b> Вначале, Бог использует нас в небольшом служении Ему. 
      Никто бы из нас не справился с гордостью, если бы Он сразу доверил нам что-то значительное.
    </Paragraph>

    <Question text="1 Тимофею 3:6"/>

    <Paragraph>
      Не презирай маленькие начинания <b>(Захария 4:10)</b>. Может Бог тебе и не скажет вначале: 
      «Ты изменишь Эфиопию навсегда». Но Он может тебе сказать: «Иди в церковь пораньше, чтобы помочь раставить стулья». 
      Это естественно ожидать больших возможностей, но вы никогда не сможете услышать призыв вашей жизни, 
      если вы неверны в том, что слышите от Бога каждый день.
    </Paragraph>

    <Question text="От Матфея 25:23"/>

    <Paragraph>
      Перед вами радикальная идея. У Бога есть идеальный план, чтобы Царство Его пришло и воля Его исполнилась 
      на земле, как и на небе. Он выбирает разных христиан для выполнения разных частей Своего плана, 
      раздавая им дары, чтобы они смогли выполнить свою задачу. Когда один из Божьих слуг неверен, 
      означает ли это, что какая-то часть Его плана терпит неудачу? Бог все еще хочет выполнить эту задачу, 
      поэтому он поручит ее тому, кто добросовестно относится к своим дарам. Забытые дары ожидают людей, 
      способных их использовать. Мы не можем просто просить Бога дать их нам, но мы можем их «заслужить», 
      если будем верными в том, что имеем сейчас <b>(Мф. 25:28-29)</b>. Тогда, если у нас есть глубокое, 
      Богом данное желание получить какой-то определенный дар в нашей жизни, мы можем попросить его у Бога.
    </Paragraph>

    <Question text="1 Коринфянам 14:1"/>

    <Paragraph>
      <b>Верность в деньгах.</b> Земное богатство является проверкой для нас, насколько нам можно доверять богатство духовное. 
      Запасаемся ли мы скупо деньгами для себя или заботимся о нуждах других? Мы мудры или глупы, беспечны и нетерпеливы? 
      Планируем ли мы наперед и задумываемся о последствиях своих действий? Мы эгоистичны или служим другим? 
      Таким образом, смотря на нашу верность в сфере финансов, Бог решит, будет ли Он доверять нам Свое богатство.
    </Paragraph>

    <Paragraph>
      Верность в том, что не принадлежит нам. Согласны ли мы служить людям Божьим там, где только Бог 
      видит и ценит наш труд? Или мы хотим славы и внимания? Помощь в служении других – это Божья школа для нашего 
      характера. Она учит нас смирению и тепению. Одна большая церковь в Америке назначает всех своих молодых 
      потенциальных лидеров служить в хозяйственном отделе. Эти ребята моют полы, красят стены и чистят туалеты, 
      получая Божьи уроки, чтобы подготовиться к большему служению в будущем. Если они отказываются служить в таких 
      местах, лидеры видят, что их характер еще недостаточно сформирован, чтобы доверить им духовные обязанности.
      Бог будет содействовать нашему продвижению и росту настолько, насколько Он может, но наша неготовность быть 
      верными сейчас может замедлить или остановить Его работу в нашей жизни. Вы будете расти в Божьем Царстве настолько, 
      насколько растет ваша верность. Каждое большое служение начинается с человека, который услышал что-то от Бога и 
      был этому верен. Каждый тяжелый и болезненный удар выковывает наш характер, который потребуется нам в будущем, 
      чтобы осуществить большую работу. Без борьбы и проверки на верность люди не смогли бы стать теми, кому Бог 
      может доверить большую ответственность. Если вы будете верными, вы обязательно увидете плоды.
    </Paragraph>

    <Question text="Притчи 28:20"/>
    
    <Paragraph>
      У Бога есть Своя роль, а у нас — своя. Его роль заключается в том, чтобы избрать нас и призвать. 
      А наша роль — в том, чтобы отозваться на Его призыв и быть верными. Пределы нашего будущего служения 
      в наших руках. Души людей находятся под угрозой. Развивайте в себе решимость, и будьте верными слугами.
    </Paragraph>

    <Question text="Откровение 17:14"/>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text="ПослеУрока1">Каким образом враг пытался остановить вас быть верными?</Question>
    <Question text="ПослеУрока2">
      Приведите один пример того, когда вы отводили свой взгляд от Господа на обстоятельства вокруг вас, 
      как это сделал Петр в Евангелии от Матфея 14: 29-31?
    </Question>
    <Question text="ПослеУрока3">Были ли вы уже вознаграждены в какой-то сфере вашей жизни, потому что были верными?</Question>

    <Title>ВОПРОСЫ В КОНЦЕ НЕДЕЛИ:</Title>
    <Paragraph>Как на этой неделе вы были верны:</Paragraph>
    <Question text="Проверочный1">— В мелочах?</Question>
    <Question text="Проверочный2">— В деньгах?</Question>
    <Question text="Проверочный3">— В том, что не принадлежит вам?</Question>
    <Question text="Проверочный4">Вы желаете получить какой-то дар или помазание? Каким образом вы сможете получить это?</Question>
    <Question text="Проверочный5">Напишите, что значит: «Пределы вашего служения в ваших руках». Правда ли это?</Question>
  </div>
);

Week07.propTypes = {};

Week07.defaultProps = {};

export default Week07;
