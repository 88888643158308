import React from 'react';
import './Week01.scss';
import 'firebase/firestore';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';

function Week01 (props) {

  return(
    <div className="Week01" data-testid="Week01">
      <LessonHeader
       image='https://images.pexels.com/photos/2422463/pexels-photo-2422463.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
       week='Неделя 1:'
       title='ПРАКТИЧЕСКИЕ КЛЮЧИ В ИЗУЧЕНИИ БОЖЬЕГО СЛОВА'/>

      <Paragraph>
        Многие христиане стыдятся своего незнания Слова Божьего. Они начинают отчаиваться в поисках нужного им места в Библии. 
        Они задаются вопросом: «Хм... разве это не было на левой стороне страницы, где-то в середине?» 
        Из-за этого незнания, они боятся свидетельствовать. Ведь, что, если человек, которому они будут свидетельствовать, 
        скажет: «Так написано в Библии, да? Покажи мне!»'
      </Paragraph>


      <Paragraph>
        Все, что они знают о Боге, пришло от других людей, поэтому они завидуют тем, кто хорошо знает Библию. 
        Как птенцы с открытым ртом, они с нетерпением ждут духовной пищи от тех, кто отдал свое время и усилия на изучение 
        Священного Писания. Господь призывает нас, христиан, знать и жить по Его Слову. «Все Писание богодухновенно и полезно 
        для научения, для обличения, для исправления, для наставления в праведности, да будет совершен Божий человек, 
        ко всякому доброму делу приготовлен» <b>(2 Тим. 3:16-17</b>).
      </Paragraph>

      <Paragraph>
        Бог призывает всех познавать Его Слово! Он использовал простых людей, пастухов и рыбаков, 
        чтобы оно было записанно <b>(см. Деяния 4:13)</b>. Библия настолько богата, что мы никогда не 
        сможем понять ее до конца <b>(см. Рим. 11:33)</b>, но в тоже самое время Божья истина настолько проста, 
        что ее может понять даже ребенок <b>(см. 2 Тим. 3:15)</b>. Только вопрос в том, будем ли мы дисциплинировать 
        себя, чтобы узнать ее?
      </Paragraph>
      
      <Paragraph>
      Изучать Библию возможно, прикладывая немного дисциплины. Читатель, тратя в среднем 15 минут 
      в день, может прочитать Библию меньше, чем за 1 год. Вместо того чтобы думать о толщине Библии 
      и безнадежности узнать Божье Слово, вам необходимо понять, что разбив его на небольшие части, 
      вы можете достичь своей цели! Выполнив это, вы почувствуете, что достигли чего-то. 
      «Желание исполнившееся приятно для души» <b>(Притчи 13:19)</b>.
     </Paragraph>

      <Title>БОЖЬЕ СЛОВО</Title>

      <Question text='От Иоанна 6:63'/>

      <Question text='Oт Иоанна 14:17'/>

      <Question text='Oт Иоанна 16:13'/>

      <Paragraph>
        Когда вы слышите проповедника, проповедующего из Библии, тогда, это 
        Дух Святой использует его или ее, чтобы учить вас. Когда вы читаете Писание, попросите Духа 
        Святого открыть вам что-то новое. Только таким образом вы сможете познавать Божье Слово. 
        Без работы Святого Духа у нас нет надежды на понимание.
     </Paragraph>

      <Title>ПРАКТИЧЕСКИЕ СОВЕТЫ</Title>

      <Paragraph>
        Чтение Библии не должно быть трудной задачей. Вот несколько 
        практических предложений, которые помогут вам получить максимум от изучения. 
        Помните о них во время прохождения этого курса, а если это для вас не сработает, 
        попробуйте что-нибудь другое.
     </Paragraph>

      <Paragraph>
        <b>Используйте перевод Библии, который вы можете понять.</b>
        Так как старинный стиль написания может быть припятствием в обучении, 
        мы советуем вам выбрать более современные переводы Библии. «Радостная Весть» 
        (Новый Завет в переводе с древнегреческого), на наш взгляд, является одним из самых 
        лучших переводов Нового Завета на русский язык на сегодняшний день. Также хорошим является 
        Новый Перевод Библии Международного Библейского Общества. Некоторые другие легко 
        читаемые переводы могут принести в жертву точность ради ясности. Христиане, серьезно 
        изучающие Библию, могут отказываться от подобных версий.
     </Paragraph>

      <Paragraph>
        <b>Превратите чтение Библии в ежедневную привычку.</b> 
        Легко забыть о чтении Божьего Слова и позволить дням проскользнуть мимо. 
        Поэтому выделите особенное время в своем расписании для Библии. 
        Желательно, чтобы это было утро, перед тем, как у вас появятся разные 
        дела в течение дня. Не откладывайте это время допоздна, когда вы устали 
        и нужно ложиться спать. Слово Божье – это то, чем мы должны дорожить!
     </Paragraph>

      <Question text='Иов 23:12'/>

      <Paragraph>
        Один известный миссионер, прочитав Книгу <b>Иова 23:12</b>, 
        решил сделать правило в своей жизни, чтобы дисциплинировать самого себя. 
        Так как ему трудно было каждый день читать Библию, его дивизом стали слова: 
        «Нет Библии, нет завтрака!». Другими словами, он не будет есть в течение дня, 
        если вначале он не прочитает свою Библию. 
        Этот принцип может быть сильным мотивом для многих людей.
     </Paragraph>

      <Paragraph>
      <b>Установите цели и сроки.</b> Цели должны быть разумными
  и измеримыми. Цель является разумной, если она реальна и выполнима. Например, заявление «Я собираюсь прочитать весь Ветхий Завет за выходные», не является разумным. Цель должна быть измеримой, чтобы вы могли знать, когда вы ее выполните. «Я собираюсь лучше изучать Библию», не является измеримой целью. Вот несколько примеров разумных измеримых целей, которые вы можете установить после окончания этого курса:
     </Paragraph>

      <Paragraph>
        <ul>
          <li>Прочитать Ветхий Завет за 9 месяцев</li>
          <li>Прочитать Притчи за 1 месяц</li>
          <li>Прочитать Псалмы за 2 месяца</li>
        </ul>
     </Paragraph>

      <Paragraph>
        Это замечательно, ставить перед собой подобные цели, 
        достигать их и больше узавать Божье Слово!
     </Paragraph>

      <Paragraph>
        <b>Когда читаете Библию, всегда имейте под рукой ручку, карандаш или маркер.</b> 
        Большинство того, что Бог когда-либо будет говорить вам, можно найти на страницах Библии. 
        Не бойтесь подчеркивать, выделять и вставлять в скобки значимые места или писать заметки на полях. 
        Записывайте все, что Господь будет открывать вам. Если в вашей Библии есть система ссылок, 
        используйте ее и находите ссылки по стихам, которые вас заинтересуют. 
        Также, всякий раз, когда вы слышите места из Библии, использованные в проповеди 
        или в какой-то христианской книге, и вы чувствуете, что они касаются вас, пометьте их, чтобы позже к ним вернуться.
     </Paragraph>

      <Paragraph>
        Когда вы закончите чтение кокой-то книги в Библии, вернитесь к началу, 
        просматривая отмеченные места и заметки, которые вы сделали. 
        Затем, поразмышляйте нам ними, перечитывая их снова и снова. 
        Таким образом, вы запечатлите содержание каждой книги Библии в вашей памяти. 
        Отмеченные стихи в Библии, также могут помочь вам во время свидетельства другим людям. 
        Возможно, вы захотите использовать аудиозапись Евангелия и отмечать цитируемые стихи, 
        чтобы можно было быстрее найти их в Библии.
     </Paragraph>

      <Paragraph>
          В конце прочитанной вами книги Библии отмечайте даты начала и окончания чтения. 
          Делая это, вы сможете отследить, сколько раз вы прочитали данную книгу, 
          и сколько времени это у вас занимало. Когда вы заканчиваете читать какую-то часть 
          Библии и хотите приступить к следующей, взгляните на даты и проверьте, 
          какие места свежи в вашей памяти, а какие нет. Тогда вы сможете снова прочитать то, 
          что не так хорошо помните. Хорошей идеей является чтение Библии по блокам, например: 
          Евангелия, Послания, Книги малых пророков, Исторические книги и т.д. 
          Многие люди перепрыгивают во время чтения с одной книги на другую, тем самым пропуская 
          смысловую нить и контекст Писания. Библия сможет обрести больше смысла для вас, 
          когда вы будете читать ее по блокам.
     </Paragraph>

      <Paragraph>
        <b>Аудиозапись Библии.</b> Вы можете скачать Библию из интернета, слушать ее онлайн, 
        или спросить у руководителей курса. Прослушивая их по 45 минут в день, вы сможете 
        пройти весь Новый завет за 3 недели, когда чтение занимает 3 месяца. 
        Большинство людей могут найти 45 минут в день, слушая запись во время подготовки 
        к работе или школе, во время поездки по городу и т.д. Это является хорошим дополнением 
        к чтению Библии, хотя ничто не заменит время, потраченное на чтение 
        и изучение Писания и общение с Духом Святым.
     </Paragraph>

      <Paragraph>
        <b>Используйте тематическую Библию с дополнительными материалами (карты, словари, пояснения, ссылки по темам).</b> 
        Тематическое разделение Писания, историко-филологические примечания, параллельные места и 
        приложения могут быть полезным инструментом в изучении Библии.
     </Paragraph>

      <Title>ЗАПОМИНАНИЕ ПИСАНИЯ</Title>

      <Paragraph>
        Запоминание Писания имеет мощный эффект. 
        Слово Божье может и должно стать неотъемлемой часть нашей жизни, защиты и руководства.
     </Paragraph>

      <Question text='Псалом 118:9-11'/>

      <Question text='Исскушение'>
        Во время искушения в пустыне, Иисус сражался с сатаной, цитируя Писание. 
        Мы также можем использовать стихи из Библии в борьбе с искушениями. 
        Прочитайте эту историю в Евангелии от <b>Матфея 4:1-11</b>, 
        и запишите ниже ее значение.
      </Question>

      <Paragraph>
        Запоминая Слово Божье, мы готовим себя к будущим ситуациям, 
        когда нам будет нужно поделиться своей верой, дать совет другу, 
        утешить раненного человека или разобраться с испытаниями в нашей с
        обственной жизни. Случалось ли так, что Дух Святой напоминал 
        вам нужный стих в нужное время? Иисус обещал, 
        что Дух Святой напомнит нам все, что Он сказал.
     </Paragraph>

      <Question text='Иоанна 14:26'/>

      <Paragraph>
        Многие христиане думают, что они не смогут запомнить наизусть Писание. 
        Существует множество разных способов запоминания, но повторение является ключевым. 
        Подумайте о песнях, которые вы знаете. 
        Как вы выучили их? Скорее всего, вы не включали диск, 
        с намерением заставить себя выучить эти песни. 
        Вы заучили слова, слушая их снова и снова. 
        Если вы голодны по Слову Божьему и проводите время, 
        читая его, вы сможете запомнить много стихов, даже не пытаясь выучить их. 
        А те стихи, которые являются значительными в ваших отношениях с Господом стоят того, 
        чтобы их заучить наизусть. Записывайте стихи на карточках и носите их везде с собой. 
        У каждого из нас есть моменты в течение дня, когда мы можем перечитывать и заучивать их.
     </Paragraph>

      <Paragraph>
      Бог обещает благословить всех, кто со смирением принимает в свое сердце Его Слово. 
      «Так говорит Господь: небо – престол Мой, а земля – подножие ног Моих;... 
      Ибо все это сделала рука Моя, и все сие было, говорит Господь. 
      А вот на кого я призрю: на смиренного и сокрушенного духом и 
      на трепещущего пред словом Моим» <b>(Исаия 66:1-2)</b>.
     </Paragraph>

      <Paragraph>Давайте изучать Слово Божье!</Paragraph>

      <br></br>
      <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
      <Question text='ПослеУрока1'>
        Как регулярно вы читали Библию до начала этого курса?
      </Question>

      <Question text='ПослеУрока2'>
        Вы когда-нибудь устанавливали разумные измеримые цели для чтения Библии? Если да, то достигли ли вы их?
      </Question>

      <Question text='ПослеУрока3'>
        Какие из практических советов, обсуждаемых на этом уроке, вы уже использовали? А какой из них помог вам в изучении Библии больше всего?
      </Question>

      <Question text='ПослеУрока4'>
        Вы когда-нибудь запоминали Библейские стихи? Если да, это было легко или сложно? Использовали ли вы раньше советы, данные выше, для запоминания Писания?
      </Question>


      <br></br>
      <Title>ПРОВЕРОЧНЫЕ ВОПРОСЫ:</Title>
      <Question text='Проверочный1'>
        Как изменилось ваше чтение Библии на этой неделе?
      </Question>
      
      <Question text='Проверочный2'>
        Запишите две вещи, которые вы узнали во время чтения Библии на этой неделе.
      </Question>

      <Question text='Проверочный3'>
        Открылось ли вам что-то новое через те отрывки, которые вы уже много раз читали раньше?
      </Question>

      <Question text='Проверочный4'>
        Напоминал ли вам Дух Святой места из Библии на этой неделе? Когда и как?
      </Question>
    </div>
  );
}

export default Week01;
