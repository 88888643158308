import React from 'react';
import './Week12.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';

const Week12 = () => (
  <div className="Week12" data-testid="Week12">
    <LessonHeader image="https://images.unsplash.com/reserve/Y1hediOeRoya666XCjYg_forest.jpg?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1650&q=80"
                  week="12 НЕДЕЛЯ"
                  title="КАК ПРОДОЛЖИТЬ СВОЙ ДУХОВНЫЙ РОСТ"/>

    <Paragraph>
      Основная проблема многих верующих — это застойная христианская жизнь. 
      Может быть у них и не было очевидных грехов, но рвение и страсть к Богу, которые были когда-то, исчезли. 
      Они перестали расти в вере. Застой начинается с удовлетворенности, что приводит людей к духовной скуке и 
      потере интереса к Богу. Впоследствии они все больше погружаются в интересы мира и превращаются в вялых христиан.
    </Paragraph>

    <Title>МЕДЛЕННО ТОНЕМ</Title>

    <Paragraph>
      Вялые христиане перестают искать Божьего Царства, и начинают искать того, чего ищут язычники <b>(Мф. 6:31-33)</b>. 
      Затем, они теряют чувствительность к голосу Духа Святого и медленно погружаются в духовные зыбучие пески. 
      Коварство приходит в их сердце и ум. Внешне все выглядит в порядке, но духовная жизнь человека превратилась 
      в оболочку, которая прекрывает внутреннюю пустоту. И, в конечном итоге, многие оставляют свою первую любовь.
    </Paragraph>

    <Question text="Откровение 2:4"/>

    <Paragraph>
      Обратите внимание, что Иисус не говорил, что они потеряли свою первую любовь, как можно потерять сокровище, 
      которое человек будет искать, пока не найдет <b>(Лк. 15:8-9)</b>. Но Он сказал, что они оставили свою первую любовь. 
      Это говорит нам о сознательном выборе отдать место, принадлежащее только Иисусу, другим вещам и позволить 
      им управлять. Слово Божье предупреждает нас об этой ловушке.
    </Paragraph>

    <Question text="Колоссянам 3:1-2"/>
    <Question text="От Луки 21:34"/>
    <Question text="От Матфея 13:22"/>

    <Paragraph>
      Печально то, что такие верующие могут по-прежнему посещать церковь, быть добрыми, нравственными людьми, 
      отдавать десятину, но при этом не иметь реальных отношений с Богом, которые когда- то у них были.
    </Paragraph>

    <Question text="Римлянам 12:11"/>
    <Question text="Иеремия 2:13"/>

    <Paragraph>
      В предыдущем стихе, Бог описывает Себя, как источник живой воды. Он является источником нескончаемого 
      свежего потока духовной жизни, но Его люди отвернулись от Него и высекли себе водоемы. Сначала, вода в них была свежей, 
      а потом быстро застоялась. Жуки и водоросли сделали ее непригодной для питья, и у водоема не было источника, 
      чтобы пополнять запасы воды. Это очень похоже на жизнь многих верующих, которые пытаются пополнять Божьи благословения, 
      живя от одного духовного подъема до другого. Наверное, вы знаете таких христиан, которые отчаянно ждут очередного лагеря, 
      конференции, которые бы помогли им укрепить их ослабевшую жизнь с Господом. Печально, что они не понимают, что оставаясь 
      в гармонии Богом, они бы получали постоянное освежение.
    </Paragraph>

    <Paragraph>
      А еще печальнее то, что подобный духовный уровень - это все, что ожидают многие церкви: верное посещение, 
      регулярная десятина и добропорядочная жизнь. Тем не менее, все эти внешние действия не смогут 
      заменить правильное сердце перед Богом. Даже живя так, чистое сердце является лишь отправной точкой в жизни христианина.
    </Paragraph>

    <Paragraph>
      Мы хотим помочь вам не стать посредственными христианами и сохранить внутренний огонь. 
      Мы не хотим, чтобы из молодого, горячего христианина вы превратились во взрослого самодовольного человека, 
      застрявшего в колее повседневной жизни, погрязшего в материализме и пропустившего Божий план для своей жизни. 
      Наш Отец прославляется, когда мы приносим много плода <b>(Ин. 15:8)</b>, и мы желаем, чтобы вы были верующими, приносящими плод, 
      даже не в 30 или 60, но во 100 крат. Вот несколько идей, как можно избежать ловушки безрадостного христианства, 
      бесполезного для Божьего Царства и не пропустить приключений с Иисусом.
    </Paragraph>

    <Title>КЛЮЧИ К ПОДДЕРЖАНИЮ ДУХОВНОГО ОГНЯ</Title>

    <Paragraph>Стремитесь к общению с людьми, чья духовная зрелость выше вашей собственной.</Paragraph>

    <Question text="Притчи 13:21"/>

    <Paragraph>
      Обычно, мы становимся похожи на тех людей, с кем больше всего проводим свое время. 
      Не то, чтобы эти люди нас чему-то официально учат, но мы впитываем их образ жизни в свою жизнь. 
      Влияние может быть хорошим или плохим, в зависимости от людей, с которыми мы общаемся.
    </Paragraph>

    <Question text="Притчи 12:26"/>
    <Question text="1 Коринфянам 15:33"/>

    <Paragraph>
      Бог не желает, чтобы мы держались в стороне от неспасенных людей. Иисуса называли другом грешников <b>(Мф. 11:19)</b>, 
      и нам нужно иметь отношения с неверующими, любя их и указывая им на Господа. Но большой вопрос в том, кто на кого влияет? 
      Кто направляет дружбу? Кто выбирает, куда идти и что делать? Помните о том, что нужно избегать обязательств между вами 
      и неверующими. Это такие отношения, в которых вы запрягаетесь в одну упряжку, связывая себя обещаниями и ответственностью, 
      особенно, это касается романтических отношений и брака.
    </Paragraph>

    <Question text="2 Коринфянам 6:14-18"/>

    <Paragraph>
      Не долго затупить лезвие топора <b>(Екклесиаст 10:10)</b>. Для того, чтобы быть инструментом Господа, нам нужно быть 
      в хорошей духовной форме. После всех усилий, которые вы вложили в укрепление своей духовной жизни в IDC, 
      не позвольте неверующим друзьям утянуть вас вниз. Регулярно проводите время с людьми, которые являются примером 
      христианской жизни. Ищите тех, кем бы вы восхищались, истинных мужей и жен Божьих. Если вы построили тесные отношения 
      с вашим наставником, то почему бы не спросить его, если бы вы могли продолжать быть подотчетным ему и встречаться 
      для молитвы?
    </Paragraph>

    <Paragraph>
      Ищите вызовов в вашей жизни, которые будут подталкивать вас становиться такими, как Иисус. 
      Слушайте учения, которые будут ободрять вас развивать свою духовную жизнь. Часто вы будете слышать призыв к посвящению, 
      святости, самопожертвованию, переживанию за потеряных и даже к мученичеству. Острый меч Духа, который является Словом 
      Божьим, раскрывает истину, в которой мы еще не живем. Мы можем почувствовать себя неуютно, как люди, 
      которых Бог призывает выйти из своей комфортной зоны. Но каждому из нас нужны Божьи послания, после которых 
      мы говорим: «Аминь!» и после которых мы говорим: «А-а-ай!». Но это не означает, что мы должны искать 
      сомнительных учений, которые не являются библейскими.
    </Paragraph>

    <Question text="Евреям 13:9"/>
    <Question text="От Матфея 16:6"/>

    <Paragraph>
      Никогда не читайте материалы разных культов таких, как Книга Мормонов или Коран, если только Бог не 
      направляет вас (например, если вы свидетельствуйте члену этого культа), и ваши духовные лидеры знают об этом. 
      Ложные религии имеют фальшивую силу, которая является демоническим помазанием на их заблуждениях.
    </Paragraph>

    <Question text="1 Тимофею 4:1"/>
    <Question text="Галатам 1:6-9"/>

    <Paragraph>
      Учения, которые нам необходимы, чтобы возрастать, должны содержать больше, чем просто факты и идеи. 
      Они должны ободрять нас исследовать Писание и бодрствовать в молитве. Чтобы совершить великую работу очищения в 
      нашей жизни, Богу необходимо поднять зло из глубины наших сердец и вынести его на свет. Хорошо иметь такой взгляд на себя: 
      “Я еще так многого не достиг!” Учения, ведомые Духом Святым, всегда будут указывать на Божью благодать, а 
      не на человеческие усилия, чтобы измениться. Враг может попытаться вселить в нас безнадежность и чувство вины через 
      послания, обличающие нас, но, в конце концов, человек, ищущий Бога, обретет от этого пользу.
    </Paragraph>

    <Question text="Евреям 12:5-11"/>

    <Paragraph>
      Никогда не прерывайте работу Духа Святого в вашей жизни, избегая правды. 
      Не отбрасывайте обличение, путая его с осуждением. Многие христиане избегают проповедей, которые заставляют 
      их поволноваться, из-за принятого в некоторых церквях менталитета “не думай о себе плохо”. 
      В посланиях, которые отвергает наша плоть, мы нуждаемся больше всего.
    </Paragraph>

    <Question text="Псалом 84:9"/>

    <Paragraph>
      Давайте будем, как Иаков, который боролся с Ангелом Божьим всю ночь и ушел хромым, но преобразованным <b>(Бытие 32:24-32)</b>. 
      Бывают моменты, когда мы боремся с Богом, не желая слышать, как Он призывает нас изменить что-то в 
      нашей жизни. После того, как мы пройдем путем Иакова, мы увидим, что наш характер изменился.
    </Paragraph>

    <Paragraph>
      Следите за вашей духовной диетой. Нам всем необходим баланс в количестве и рационе нашего питания. 
      Диета спортсменов, готовящихся к Олимпиаде, отличается от диеты обычных людей. Они знают, что лишние 
      пару пончиков там и здесь, могут удержать их от золотой медали. Когда они тренируются и сжигают много калорий, 
      им необходимы витамины и дополнительное количество хорошей пищи. Точно так же, христианин, 
      который хочет быть в центре Божьей воли, должен быть осторожен, избегая всего нечестивого и съедая 
      дополнительные порции с Божьего стола.
    </Paragraph>

    <Paragraph>
      Одного-двух служений в неделю недостаточно, чтобы пополнить свои запасы духовной пищи, но это все, 
      чего хотят многие христиане. Многие пасторы и духовные лидеры нацелены на средний уровень духовной зрелости на служении, 
      а растущему верующему нужно больше, чем может дать воскресное собрание. Если ваш лидер предлагает специальный 
      курс по изучению Библии, посещайте его. Впитывайте все, что можете, от Божьих людей, предлагающих вам духовное 
      наставничество.
    </Paragraph>

    <Paragraph>
      Научитесь кормить самих себя. Это нормально, когда птенец сидит в гнезде с открытым ртом, ожидая, 
      когда мама что-нибудь туда опустит. Но когда птица подрастает, она должна сама искать себе пищу. 
      И вы, в конечном итоге, должны научиться кормить себя духовно. Во- первых, вам нужно определиться, какой духовной 
      диете вы хотите следовать. Вы увидете, что у каждого духовного лидера есть свое понимание и свое послание. 
      Один сосредотачивается на молитве, другой говорит об евангелизме, третий – о делах милосердия.
    </Paragraph>

    <Paragraph>
      Расширяй свой духовный рацион, наблюдая за людьми Божьими, которые имеют откровения в различных областях. 
      Я многому научился, ища Божью истину таким образом. От одного пастора я узнал, что такое быть во Христе, 
      а от другого - что означает иметь бремя за души других. Один евангелист уделял особое внимание святости, другой – 
      молитве, третий – помазанию и чудесам. Через миссионера я воочию убедился, что такое миссия и как быть верным в 
      призвании. Лидеры, имеющий жизненный опыт — это лучшие учителя.
    </Paragraph>

    <Paragraph>
      Практикуйте все, что вы узнаете. Все, что вы узнаете своим разумом, но не практикуете, превращается в духовный жир, 
      вместо мускул. Если Бог открывает вам что-то о Себе и Своей истине, живите так и расскажите об этом другим. 
      Таким образом, вы открывете себе еще больше возможностей для духовного роста и помогаете расти другим.
    </Paragraph>

    <Question text="Иакова 1:22-25"/>
    <Question text="1 Коринфянам 8:1"/>

    <Paragraph>
      Израиль имеет два крупных водоема. Галилейское море полно рыбы и других форм жизни, в то время, 
      как Мертвое море, увы, мертво. Ничто и никто не может выжить в Мертвом море. Разница между двумя этими 
      водоемами в том, что в Галилейское море втекает большое количество речушек, а затем, они находят выход в реке Иордан. 
      Вода в таком водоеме никогда не застаивается. Мертвое же море только получает воду, но не отдает ее, 
      что делает его безжизненным.
    </Paragraph>

    <Paragraph>
      Если мы не отдаем то, что получаем от Бога, мы теряем важнейший из ключей личного духовного роста и 
      удерживаем других от получения благословений. Мы несем ответственность, как перед Господом , так и перед людьми.
    </Paragraph>

    <Question text="От Луки 12:48(2)"/>
    <Question text="2 Тимофею 2:2"/>

    <Paragraph>
      Будьте верными в духовной дисциплине. Через требования нашего курса вы приобретаете привычки, которые помогут 
      вам в течение всей жизни, если вы продолжите следовать им. Вы изучали Писание, молились, отдавали десятину, 
      учились ответственности, переживали за потеряных и отвергали влияние этого мира. После окончания обычный процесс 
      для многих – это медленная остановка. НЕ ОСТАНАВЛИВАЙТЕСЬ! Продолжайте подталкивать себя туда, куда Бог ведет вас.
    </Paragraph>

    <Question text="Филиппийцам 3:12-14"/>

    <Paragraph>
      Настоящий ключ к продолжающемуся росту – это сердце, ищущее Бога. Тосковали ли вы по Богу? 
      Молились ли вы с отчаянным желанием знать Его больше, ходить в близости с Ним, угождая Ему каждый день? 
      Писание открывает нам такое рвение, которое и по сей день живет в сердцах Божьих учеников по всему миру.
    </Paragraph>

    <Question text="Псалом 26:8"/>
    <Question text="Псалом 41:2-3"/>
    <Question text="Псалом 83:2-3"/>

    <Paragraph>
      Если вы хотите избежать превращения в посредственного бесплодного христианина, 
      примите одно простое правило для вашей жизни: ищите Господа и соблюдайте то, что Он говорит.
    </Paragraph>

    <Paragraph>
      Будьте осторожны в том, кому вы подчиняетесь как духовному лидерству. Избегайте церквей, послание которых: 
      “просто прийди, посиди и отдай десятину”. Не посещайте церковь с утешительными и приятными проповедями, 
      которые продвигают мирские ценности. Остерегайтесь эгоистичных взглядов в служении: “будь тем, кто ты есть, 
      достигай богатства, успеха и открывай свой внутренний потенциал”. Это гуманизм, и неважно, сколько мест 
      из Писания такие служители могут привести, чтобы поддержать свое учение. Будьте осторожны с гламурными и 
      показными служениями. Они, как правило, оказываются плотскими. Бог чудесен, и вы будете чувствовать смирение 
      и сломленность в Его верных слугах. Так же, как и во времена Ветхого Завета, Бог не жалает ничего плотского 
      в Своем Присутствии <b>(Исход 20:26)</b>.
    </Paragraph>

    <Paragraph>
      Даже огромные и известные служения не будут угодны Господу, если они сосредоточены на человеке, вместо Бога. 
      Помните, что это не наше дело судить о них, но мы должны следить за своими плодами. Не размер служения важен 
      для Бога, но его суть (1 Кор. 3:13). Небеса откроют нам некоторые сюрпризы, когда будет раздаваться награда.
    </Paragraph>

    <Question text="От Матфея 19:30"/>

    <Paragraph>
      Настоящее служение будет достигать целей Бога, которые Он открывает в Своем Слове. 
      Люди будут слышать призыв оставить зло этого мира и служить Богу в святости.
    </Paragraph>

    <Question text="Иезекииль 44:23"/>

    <Paragraph>
      Посещайте церковь. Следите за тем, чтобы всегда быть частью какой-то церкви в течение всей вашей христианской жизни. 
      Очень легко оправдываться и критиковать церковь, но это смертельная ошибка. Ходите в церковь!
    </Paragraph>

    <Question text="Евреям 10:25"/>

    <Paragraph>
      Это возможно иметь динамичную христианскую жизнь с этого момента, и пока вы не увидите Господа лицом к лицу. 
      У вас нет причин останавливаться и позволять вашему сердцу остыть.
    </Paragraph>

    <Paragraph>
      <b>
        Продолжайте подталкивать себя и следовать за Иисусом!
      </b>
    </Paragraph>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text="ПослеУрока1">Случалось ли в вашей жизни, что после духовного роста наступали времена удовлетворенности и застоя?</Question>
    <Question text="ПослеУрока2">Почему «да» или почему «нет»?</Question>
    <Question text="ПослеУрока3">Что может помочь поддерживать огонь и продолжать расти духовно?</Question>

    <Title>ВОПРОСЫ В КОНЦЕ НЕДЕЛИ:</Title>
    <Paragraph><b>Что вы намерены делать, чтобы продолжить свой духовный рост после окончания IDC?</b></Paragraph>
    <Question text="Проверочый1">а) </Question>
    <Question text="Проверочый2">б) </Question>
    <Question text="Проверочый3">в) </Question>
    <Question text="Проверочый4">г) </Question>

    <Paragraph><b>Как вы можете передать другим то, чему Бог научил вас во время IDC?</b></Paragraph>
    <Question text="Проверочый5">а) </Question>
    <Question text="Проверочый6">б) </Question>
    <Question text="Проверочый7">в) </Question>
    <Question text="Проверочый8">г) </Question>
  </div>
);

Week12.propTypes = {};

Week12.defaultProps = {};

export default Week12;
