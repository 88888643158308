import React from 'react';
import './Week03.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';
import Illustration from '../lessonComponents/Illustration/Illustration';

const Week03 = () => (
  <div className="Week03" data-testid="Week03">
    <LessonHeader image="https://images.unsplash.com/photo-1473993702977-1706a7f23164?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
    week='3 НЕДЕЛЯ'
    title='ЧАСТЬ 2: МОЛИТВА И ДУХОВНАЯ ВОЙНА'/>

    <Paragraph>
      Предыдущий урок был о том, что в основе нашей жизни должна быть молитва – 
      наши личные отношения с Богом. На этой неделе мы сделаем следующий шаг, 
      учась молиться за нужды других. Самым главным приоритетом Господа является то, 
      чтобы у нас были близкие отношения с Ним. А следующий приоритет – это работа, 
      которую мы делаем для Бога (Марка 3:14), что также включает и молитву. 
      Когда мы меняем местами эти две вещи, мы обрекаем себя лишь на религиозные 
      добрые дела, вместо плодотворной христианской жизни. Мы превратимся в 
      разочарованных и сухих людей, удивляясь, почему у нас ничего не получается. 
      В результате, мы захотим еще больше стараться получить духовные результаты, 
      которые невозможны для нас, живя независимо от Бога <b>(Иоанна 3:6)</b>. 
      Прежде чем вы попытаетесь выполнить любую работу для Господа, убедитесь, 
      что вы живете в подлинном общении с Ним.
    </Paragraph>

    <Title>МОЛИТВА КАК РАБОТА И БИТВА</Title>

    <Paragraph>
      Сильная молитвенная жизнь предполагает настоящую работу и духовную брань. 
      В Писании мы видим лидеров, которые усердно трудились в молитве <b>(Колоссянам 4:12)</b>, 
      а история показывает нам, что молитва всегда предшествовала продвижению Царства Божьего 
      в новые области. Молитва — это прямой вызов сатанинской власти над человечеством.
    </Paragraph>

    <Question text="От Иоанна 5:19"></Question>

    <Paragraph>
      Мы будем сталкиваться с оппозицией сатаны, когда мы молимся. 
      Обратитесь снова к диаграммам, которые показывают три уровня молитвенной 
      жизни христианина. Ричард Сиббс, живший почти 400 лет назад, сказал: 
      «Когда мы приходим к Богу в молитве, дьявол знает, что мы получаем силу против него, 
      поэтому, он пытается остановить нас, как только может». 
      Это так же верно и сегодня! Однако дьявол не пытается вмешиваться на первом уровне. 
      Когда вы в последний раз участвовали в великой духовной битве во время молитвы перед 
      едой? Его больше беспокоит, когда мы начинаем вести посвященную молитвенную жизнь, 
      потому что это первый шаг к близости с Богом, а близость с Ним дает силу.
    </Paragraph>

    <Paragraph>
      Множество разных вещей будет пытаться отвлечь вас от очередной встречи с Богом. 
      Тем не менее, вы не составляете угрозу царству тьмы, пока не начнете двигаться вперед 
      и прорываться через барьеры в вашем хождении с Господом, осознавая, что ваша 
      собственная жизнь, нужды, интересы, желания уже не являются самым главным для вас. 
      В тот момент, когда ваше внимание направлено на волю Божью, и вы видите, как Царство 
      Бога приходит, тогда и начинается настоящая битва!
    </Paragraph>

    <Paragraph>
      Один из способов оценить глубину вашей молитвенной жизни - 
      это посмотреть насколько сильно ваши молитвы вращаются вокруг вас самих. 
      Бог знает ваши нужды и обещал восполнять их.
    </Paragraph>

    <Question text="От Матфея 6:32"/>
    <Question text="Филиппийцам 4:19"/>
    <Question text="Филиппийцам 4:6"/>

    <Paragraph>
      Мы рады, что можем приносить наши нужды перед Богом, но часто, это все, 
      о чем молится большинство христиан. Но молитва – это намного больше! 
      Когда мы перестаем быть эгоистичными в молитве и ищем Царства Божьего, 
      тогда все остальное становится на свои места.
    </Paragraph>

    <Question text="От Матфея 6:33"/>
    <Question text="Колоссянам 3:1-3"/>

    <Paragraph>
      Нам больше не нужно основную часть времени молиться за самих себя, 
      но посвятить наши молитвы ходатайству, чтобы Царство Божье пришло в 
      жизни других людей. Словарь определяет слово ходатайство, 
      как «мольба за кого-то, в частности, молитва и прошение к Богу от имени другого лица». 
      Сатана знает, насколько это может быть опасно для его разрушительных планов в мире, 
      поэтому он будет стараться создавать нам много личных проблем, 
      чтобы вернуть наше внимание на самих себя. Это могут быть проблемы в семье, 
      атаки на здоровье, необъяснимое разочарование и депрессия, финансовые трудности, 
      неудачи в школе, на работе, проблемы в отношениях с людьми, и вы почувствуете, 
      что все идет как-то не так. Но продолжайте жить по вере, хранить правильные приоритеты в 
      жизни, и вы увидите, что атаки стихают, и вы окажетесь победителем! 
      Распознав причины наших проблем и посвящая себя Богу, мы приобретаем привычку молиться, 
      что помогает нам преодолеть препятствия и заставляет двигаться вперед.
    </Paragraph>

    <Paragraph>
      У нашего врага есть стратегия нападения, которую он использует против серьезной молитвенной жизни. 
      Так как он не может препятствовать молитвам достигать Бога, 
      его первой задачей является остановить молитву еще перед тем, как она будет произнесена. 
      (Его вторая цель – помешать нам получить ответы на наши молитвы. Обсуждается позже.) 
      Остановка – это основная оборона сатаны против власти наших молитв, 
      а союзником ему в этом является наша же собственная плоть. Плоть не хочет молиться, 
      поститься или слушаться Бога. Это бунтовщик, ленивый и упрямый. 
      Плоть постоянно склоняется к эгоизму и всякого рода греху. Вот почему Бог призывает нас жить по Духу, 
      а не по плоти.
    </Paragraph>

    <Question text="Галатам 5:16-18"/>
    <Question text="Римлянам 8:6-7"/>

    <Paragraph>
      Плоть живет чувствами, и она легко разочаровывается и обижается. 
      Она говорит нам, что у нас есть дела и поважнее, чем молитва. Плоть говорит 
      «Да!» каждому аргументу сатаны, который пытается удержать нас от молитвы. 
      Еще ни один парень или девушка не смогли стать лучше вне их молитвенной жизни. 
      Мы не сможем быть близко с Богом, если мы не проводим времени с Ним. 
      Мы не увидим ответы на молитвы, если мы не молимся. Наша эффективность 
      как христиан начинается с наших личных отношений с Богом. Мы не должны позволить, 
      чтобы плоть победила нас. Нам нужно научиться реальной жизни в молитве!
    </Paragraph>

    <Title>ПРЕПЯТСТВИЯ В МОЛИТВЕ</Title>

    <Paragraph>
      Мы можем судить об эффективности нашей молитвенной жизни по тому, 
      насколько плоть и сатана противятся этому. Есть множество препятствий в молитве, 
      но у Бога есть решение для каждого из них.
    </Paragraph>

    <Paragraph>
      <b>Выделить время для молитвы.</b> Самое трудное в молитве – найти время, 
      чтобы сделать это. Все выглядят такими занятыми. Главный вопрос заключается в наших с вами приоритетах. 
      Насколько важно для вас искать Господа, ходатайствовать за нужды семьи и друзей, 
      молиться за потерянный мир перед престолом Бога? Если вы слишком заняты для молитвы, 
      тогда ваша жизнь не в порядке. Что за низкие приоритеты контролируют ваше время и душат вашу молитвенную жизнь? 
      Если была бы такая необходимость, смогли бы вы пренебречь сном, чтобы провести время в молитве?
    </Paragraph>

    <Question text="Матфея 26:40-41"></Question>
    <Question text="1 Петра 4:7"></Question>

    <Paragraph>
      Готовы ли вы оставить незначительные мероприятия, чтобы помолиться? 
      (Какие мероприятия вы считаете незначительными?) Давайте рассмотрим и другие препятствия в молитве, 
      и то, как Бог может помочь вам их преодолеть.
    </Paragraph>

    <Paragraph>
      <b>Сонливость.</b> Вы можете обнаружить утром, что абсолютно проснулись, а после того, 
      как начали молиться, снова почувствовали сонливость. Это духовная битва, не сдавайтесь и не дайте 
      себе вздремнуть! Кроме того, следует знать, что вы не сможете молиться рано утром или поздно ночью, 
      уютно расположившись в вашей постели. Даже на коленях в тихой комнате вы можете легко заснуть, 
      но вы никогда не заснете во время ходьбы. Также попробуйте молиться вслух, удерживая свое внимание на молитве. 
      Помните, что поздние ночи убивают утреннюю молитву. 
      Если утреннее время с Богом – это действительно ваш приоритет, тогда ложитесь спать рано.
    </Paragraph>

    <Paragraph>
      <b>Блуждающие мысли.</b> Пленяйте каждую свою мысль для Христа. Мысли о насущных нуждах, 
      незаконченных делах, на голодный желудок, о том, что вы видели по телевизору и множество других 
      размышлений отвлекают нас. Просите Господа, чтобы Он взял власть над вашими мыслями в то время, 
      когда вы молитесь. Если Бог напоминает вам о чем-то, что вам нужно сделать, запишите это и 
      продолжайте молиться. Это очень отличается от тех молитв, когда наш разум невнимателен, 
      и уже через 10 минут мы понимаем, что давно перестали молиться.
    </Paragraph>

    <Question text="2 Коринфянам 10:4-5"/>

    <Paragraph>
      Когда вы обнаружите, что ваш разум блуждает, немедленно вернитесь к молитве. 
      Просто начните с того, где вы остановились. Не посвящайте оставшееся время молитвы, 
      чтобы осуждать и винить себя. Опять же, молитва вслух очень помогает.
    </Paragraph>

    <Question text="1 Иоанна 3:21-22"/>
    <Question text="Притчи 28:13"/>
    <Question text="1 Иоанна 1:8-9"/>

    <Paragraph>
      Иногда чувство вины является справедливым, а не просто атакой врага. 
      Помните, что есть разница между чувством вины и ситуацией, когда вы по-настоящему виноваты. 
      Враг хочет, чтобы вы чувствовали себя виноватыми во всем. Вы должны хранить чистую 
      совесть и не играть в игры с Богом. Если Господь указывает вам на грех в вашей жизни, 
      вам нужно покаяться, и прийти к престолу Бога с чистым сердцем.
    </Paragraph>

    <Question text="Псалом 65:18"/>
    <Question text="Псалом 23:3-4"/>

    <Paragraph>
      Приходить к Богу с чистым сердцем, также означает прежде убедиться, 
      что вы привели в порядок отношения с другими людьми.
    </Paragraph>

    <Question text="Деяния 24:15-16"/>
    <Question text="От Матфея 5:23-24"/>

    <Paragraph>
      <b>Вещи, которые нас отвлекают.</b> Подумайте заранее обо всем, что может прервать вашу молитву. 
      Отключите телефон, повесьте знак на вашей двери, попросите ваших домашних не отвлекать вас в это время. 
      Возможно, вам будет легче прогуляться или куда-то уехать, вместо того, чтобы молиться дома. 
      Несмотря на все меры предосторожности, вы все еще подвержены отвлечениям! 
      Это всего лишь часть битвы, поэтому не сдавайтесь, возвращайтесь к тому, на чем вы остановились и 
      продолжайте молиться. Бог не раздражается, когда что-то отвлекает нас и прерывает наше время с Ним. 
      Он лучше нас понимает, что молитва – это бой.
    </Paragraph>

    <Paragraph>
      <b>Ощущение сухости.</b> Возможно, вы не будете испытывать глубоких чувств, когда вы молитесь. 
      Когда слышишь свидетельства других христиан, как близки они с Господом, и как мурашки проходят 
      по их телу во время молитвы, легко почувствовать себя обделенным, если ваш опыт не был таким драматичным. 
      Поймите это сейчас, и не ожидайте, что потрясающие и необъяснимые вещи будут происходить во время вашей молитвы. 
      Но они будут происходить, потому что вы помолились! Господь слышит вас независимо от ваших эмоций.
    </Paragraph>

    <Question text="Псалом 33:16"/>

    <Paragraph>
      Это утверждение веры удержит вас от разочарований. У Бога есть право делать то, 
      что Он пожелает в Его собственное время, и это обычно отличается от наших представлений. 
      Невероятные посещения Бога в моей жизни, как правило, происходили не во время молитв. 
      Вместо этого, они были во время моих разговоров с кем-то, на служении в церкви или, когда Господь 
      будил меня среди ночи. Молитесь с верой и оставьте ответ за Ним. Самое худшее, что мы можем сделать, 
      это сказать: «Хорошо, Бог! У Тебя есть 15 минут, чтобы со мной поговорить».
    </Paragraph>

    <Paragraph>
      Несмотря на то, чувствуете вы или нет, ваши молитвы отодвигают назад силы тьмы и призывают власть Бога. 
      Дьявол желает, чтобы вы в это не верили, но обе стороны, ангелы и демоны, начинают двигаться, 
      когда вы молитесь. Мы слепы в духовном мире, и можем его увидеть, если только Бог нам это позволит, 
      но независимо от этого, духовный мир реален и зависит от наших молитв. 
      Вы сможете прочитать удивительную историю об этом во <b>2 Царств 6:13-17</b>.
    </Paragraph>

    <Title>В ОЖИДАНИИ ОТВЕТА НА МОЛИТВУ</Title>

    <Paragraph>Выше мы говорили о стратегиях нападения сатаны на нашу молитвенную жизнь. 
      Первая атака – это помешать нам начать молиться, а вторая направлена на получение Божьих ответов на наши молитвы. 
      Прочитайте Книгу Даниила 9-10 главу, чтобы лучше понять, что происходит в духовном мире. 
      Даниил был в посте и молитве в течение 3 недель и не получил никаких результатов. 
      Как много из нас уже бы давно сдались? И наконец-то, явился ангел с невероятной историей. 
      Он рассказал Даниилу, что был послан Богом с первого же дня, когда Даниил начал молиться, 
      но демонический «князь» не позволял ему пройти. Поэтому ангел ждал подкрепления. 
      После победы в этом бою, ангел передал послание Даниилу, и ему пришлось воевать на обратном пути в рай. 
      Все это напоминает роман Фрэнка Перетти!
    </Paragraph>

    <Paragraph>
      Вера является каналом, по которому Бог посылает ответы на наши молитвы. 
      Давайте «подвяжемся добрым подвигом веры» <b>(1 к Тимофею 6:12)</b>, пока не увидим плоды наших молитв. 
      Иногда мы сдаемся и в унынии отступаем, думая, что Бог игнорирует наши молитвы. 
      А что, если ожидаемый ответ уже на пути?!
    </Paragraph>

    <Question text="Евреям 10:35-39"/>

    <Paragraph>
      Возможно, в начале вы увидите благословение и быстрые ответы на молитву. 
      Бог, посылая их, поощряет тех, кто посвятил себя настоящей молитвенной жизни. 
      Затем наступает время испытаний, где ты получаешь только несколько ответов на молитву и 
      совсем не чувствуешь Божьего присутствия. В это время твоя вера растет, так что не сдавайся. Напрягись!
    </Paragraph>

    <Title>ПРАКТИЧЕСКИЕ КЛЮЧИ К СИЛЬНОЙ МОЛИТВЕННОЙ ЖИЗНИ</Title>
    <Paragraph>Вот несколько путей в развитии привычки молиться:</Paragraph>

    <Paragraph>
      <b>• Повинуйтесь Божьему водительству в продолжительности вашего ежедневного молитвенного времени.</b> 
      Когда ваши отношения с Иисусом возрастают, время, которое вы проводите с Ним, может быть недостаточным. 
      Возможно, сейчас вам трудно представить, о чем можно молиться в течение часа, но позже вы будете поражены, 
      как быстро этот час пролетит. В тоже время, Бога не впечатляет длина наших молитв, но их качество.
    </Paragraph>

    <Question text="Екклесиаст 5:2-3"/>
    <Question text="От Матфея 6:7"/>

    <Paragraph>
      <b>• Молитесь, чтобы Дух Святой сходил на вас не только, когда у вас регулярное время с Богом.</b> 
      Как мы уже говорили раньше, вы можете иметь общение с Богом в течение всего дня. 
      Будут моменты, кода Бог будет призывать вас оставить ваши дела для ходатайства и духовной войны, или просто, 
      чтобы поделиться с вами Своим бременем. В это время ваши молитвы будут особенно мощными и страстными.
    </Paragraph>

    <Paragraph>
      <b>• Во время молитвы имейте под рукой ручку и бумагу.</b> Это очень важно! 
      Бог будет напоминать о людях, нуждающихся в молитве, и делах, которые были забыты, 
      указывать на приоритеты и давать вам места Писания, чтобы вы размышляли.
    </Paragraph>

    <Paragraph>
      <b>• Молитесь конкретно, а не в общем.</b> 
      Пусть Святой Дух направляет ваши молитвы в соответствии с Его планом.
    </Paragraph>

    <Paragraph
      ><b>• Молитесь о том, что в вашем сердце, а не что в вашем списке.</b> 
      Молитвы по списку могут сделать ваше время механическим и скучным. 
      Список может быть полезным, чтобы не забыть о конкретных людях или местах, но также, 
      Бог желает страстных и горячих молитв.
    </Paragraph>

    <Question text="Иакова 5:16"/>
    <Question text="Римлянам 12:11"/>

    <Paragraph>Начни молиться молитвами, которые будут оказывать мощное воздействие!</Paragraph>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text="ПослеУрока1">У вас когда-нибудь был беспорядок с приоритетами, когда вы пытались сделать что-то для Бога, но вначале не наладили правильных отношений с Ним?</Question>
    <Question text="ПослеУрока2">Насколько ваша молитвенная жизнь вращается вокруг ваших личных нужд?</Question>
    <Question text="ПослеУрока3">Какие препятствия в молитве обычно бывают у вас?</Question>
    <Question text="ПослеУрока4">Как часто ты ходатайствуешь за кого-то конкретно или за группы людей, город, страну или страны?</Question>
    
    <Title>ПРОВЕРОЧНЫЕ ВОПРОСЫ:</Title>
    <Question text="Проверочный1">Приходилось ли вам духовно сражаться, защищая ваше молитвенное время на этой неделе?</Question>
    <Question text="Проверочный2">Какие из обсуждаемых нами препятствий в молитве у вас были на этой неделе?</Question>
    <Question text="Проверочный3">Возникали ли на этой неделе у вас проблемы, которые пытались сосредоточить ваши молитвы на вас самих?</Question>
    <Question text="Проверочный4">Вел ли тебя Дух Святой заступаться в молитве за кого-то на этой неделе: семья, друзья, школа, города или нации?</Question>
    <Question text="Проверочный5">Что Бог открывал тебе в молитве на этой неделе?</Question>
  </div>
);

Week03.propTypes = {};

Week03.defaultProps = {};

export default Week03;
