import React from 'react';
import './Week08.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';

const Week08 = () => (
  <div className="Week08" data-testid="Week08">
    <LessonHeader image="https://images.unsplash.com/photo-1523312190300-9a8f8131f8e2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                  week="8 НЕДЕЛЯ"
                  title="СМИРЕНИЕ"/>

    <Paragraph>
      Вопрос гордости и смирения настолько важен для Господа, что Он отдаляется от 
      тех, кто горд и приближается к смиренным. Гордость – это не простой грех. 
      Его легче обнаружить в других, чем в себе. А вы гордые?
    </Paragraph>

    <Title>ЧТО ТАКОЕ ГОРДОСТЬ?</Title>

    <Paragraph>
      Один словарь определяет слово гордость как чрезмерную самооценку. 
      Человек может гордиться многими вещами, например:
    </Paragraph>

    <Paragraph>
      <b>
        • Талантами<br/>
        • Достижениями<br/>
        • Внешним видом<br/>
        • Имуществом<br/>
        • Спортивными способностями<br/>
        • Друзьями<br/>
        • Духовностью<br/>
        • Умом<br/>
        • Знанием Библии<br/>
        • Семьей<br/>
        • Духовными дарами<br/>
        • Образованием<br/>
        • Особенностями своей личности <br/>
        • Музыкальными способностями <br/>
        • Богатством<br/>
        • Силой<br/>
      </b>
    </Paragraph>

    <Paragraph>И этот список можно еще долго продолжать...</Paragraph>

    <Paragraph>
      Гордость не может быть скрытым грехом. Она выражается в высокомерии и неуважении к 
      другим и в том, как мы отзываемся о самих себе. Гордость становится очевидной в том, 
      когда мы заставляем других ждать нас и убирать за нами. Сравнение себя с другими является основой гордости.
    </Paragraph>

    <Question text="2 Коринфянам 10:12"/>

    <Paragraph>
      Мы часто изучаем других людей и «про себя» оцениваем их по шкале, которая указана выше. 
      Наша человеческая натура всегда хочет отыскать те сферы, в которых мы можем дать себе более высокую оценку, 
      чтобы подняться в своих собственных глазах. Затем, мы ищем недостатки в других людях, 
      чтобы мы могли опустить их на более низкий уровень. Такое сочетание гордости и критического отношения оскорбляет 
      Господа и разрушает наши отношения с другими людьми.
    </Paragraph>

    <Title>ИСТОЧНИК ГОРДОСТИ</Title>

    <Paragraph>
      Гордость приходит тогда, когда мы находим свою ценность в чем-то, но не в Боге. Когда жизнь 
      человека основана на временных вещах, подобных тем, что перечислены выше, что происходит, 
      когда он их теряет? Мы все видели или слышали о людях, которые сталкивались с трагедией, забиравшей у 
      них что-то из этого списка: банкротство успешного бизнеса; красивая женщина, обезображенная страшной аварией; 
      спортсмен в инвалидной коляске; умные люди, теряющие память и способность действовать самостоятельно. 
      Жизнь на основе любого из этих источников гордости является неустойчивой. Если человек знает, что источник 
      его ценности – это неизменная Божья любовь и принятие, через кровь Иисуса Христа, тогда такой человек находится 
      в безопасности.
    </Paragraph>

    <Paragraph>
      Гордость лежит в основе зависти, осуждения, сплетен и многих других грехов. 
      Храня в себе смиренное сердце, мы сможем избежать многих неприятностей в нашей жизни.
    </Paragraph>

    <Question text="Иакова 3:14-16"/>

    <Title>ПРИМЕР СМИРЕНИЯ ИОАННА КРЕСТИТЕЛЯ</Title>

    <Paragraph>
      Иоанн Креститель был человек, посланный Богом, чтобы подготовить людей к пришествию Мессии. 
      Какое-то время он был самым знаменитым духовным лидером в стране <b>(Мф. 3:5-6)</b>. Затем, наступило время для 
      Иисуса принять это место, законно принадлежащее Ему, а для Иоанна уйти в отставку. Тогда ученики Иоанна пришли 
      к нему и попытались возбудить в нем ревность и гордость (Ин. 3:26). Ответ Иоанна показал всем настоящий Божий 
      характер. Его слава и влияние быстро исчезали, но он знал, что его роль только и заключалась в том, чтобы указать 
      людям на Иисуса, а не на себя. Иоанн знал, кем он был, и кем был Иисус. Если бы он был гордым, он 
      бы попытался сохранить толпы людей для себя, но он показал подлинное смирение.
    </Paragraph>

    <Question text="От Иоанна 1:29-30"/>
    <Question text="От Иоанна 3:30"/>\

    <Paragraph>
      Позже, Иисус назвал Иоанна величайшим человеком, когда-либо рожденным <b>(Мф. 11:11)</b>. Урок, 
      который мы можем извлечь из жизни Иоанна — это привлечение внимание не к себе, а к Иисусу.
    </Paragraph>

    <Title>ПРИМЕР СМИРЕНИЯ ИИСУСА</Title>

    <Paragraph>Если кто и достоин внимания и чести, так это Иисус.</Paragraph>

    <Question text="Колоссянам 1:18"/>

    <Paragraph>
      Подчиняясь планам Отца, Иисус отказался от искушения привлечь внимание к Себе неправильным путем в неподходящее время.
    </Paragraph>

    <Question text="От Матфея 4:8-10"/> 
    <Question text="От Иоанна 7:3-6, 8"/>

    <Paragraph>Иисус исполнял Божью волю Божьим путем и в Божье время, показывая этим наивысший пример смирения.</Paragraph>
    <Question text="Филипийцам 2:5-11"/>

    <Title>БОЖИЙ ОТВЕТ НА ГОРДОСТЬ И СМИРЕНИЕ</Title>

    <Paragraph>
      Иисус является образцом для нашей жизни. Он был смирен, и Бог превознес Его. Таким же образом Он будет поступать и с нами.
    </Paragraph>
    
    <Question text="1 Петра 5:5-6"/>

    <Paragraph>
      Когда мы смиряемся перед Богом и перед другими, Господь идет к нам навстречу. 
      Когда же мы превозносим себя, Он противится нам.
    </Paragraph>

    <Question text="Псалом 137:6"/>
    <Question text="Иакова 4:6"/>
    <Question text="1 Коринфянам 1:29"/>

    <Paragraph>
      Когда мы гордимся, мы теряем присутствие Бога в нашей жизни. Как будто Господь говорит: 
      “Думаешь, ты такой крутой? Посмотрим, как хорошо ты сможешь это сделать сам”. Иногда нам нужно пройти нелегкий путь, 
      чтобы понять насколько мы зависим от Бога. Гордость – это проявление нашей независимости, 
      которая должна отправиться на крест. Наши лучшие способности, качества и характеристики не делают нашу жизнь 
      значимой и ценной. Господь является сокровищем нашей жизни. Мы всего лишь глиняные сосуды, хранящие это сокровище в себе.
    </Paragraph>

    <Question text="2 Коринфянам 4:7"/>
    <Paragraph>
      Существует правильный и неправильный способ делать что-либо. Гордые и эгоистичные люди часто обходят 
      или игнорируют Божьи принципы в их стремлении что-то сделать. Они считают, что правила относятся к другим, 
      но не к ним. Последствия гордости и смирения абсолютно противоположны.
    </Paragraph>
    
    <Question text="Притчи 29:23"/>
    <Question text="Притчи 15:33"/>
    <Question text="Притчи 16:18"/>

    <Paragraph>
      Те, кто пытаются возвеличить себя, поймут, что Бог им активно противится. 
      В любом служении могут быть амбициозные люди. Они желают вознести себя вместо того, чтобы развивать в 
      себе характер слуги, который является единственным путем к величию в
      Божьих глазах. Самопродвижение – это гордость, которая приведет нас к неприятностям. Продвижение себя может 
      привести нас в те сферы служения и на тот уровень ответственности, на котором нам не нужно быть. 
      Если наш характер не готов для того, чтобы Бог продвигал нас, но мы двигаемся сами в нашей гордости, 
      тогда падение придет естественно. Это широкая дорога, из-за которой идущие по ней лидеры, впадают в грех и теряют 
      свое служение. Гордость обманывает таких людей, говоря им, что правила уже не для таких, как они, что они слишком важны, 
      чтобы Бог судил их за какой-то грех. Если мы пойдем по узкому пути смирения в нашем служении, мы попадем в 
      правильное место и в правильное время.
    </Paragraph>

    <Question text="Псалом 74:6-8"/>

    <Paragraph>
      Так устроена наша жизнь. Успех и благословения приходят от следования Божьим принципам: смирению, 
      справедливости, верности и страху Божьему.
    </Paragraph>

    <Question text="Притчи 22:4"/>

    <Paragraph>
      Люди в мире ищут богатства, славы и жизни, но они игнорируют Божьи пути или противятся им. 
      Люди желают величия, которое для них означает, что многие будут служить им, вместо того, 
      чтобы они служили многим. Воистину, в Царстве Бога все наоборот <b>(Мф. 20:25-28)</b>!
    </Paragraph>

    <Title>ПАДЕНИЕ ЛЮЦИФЕРА</Title>

    <Paragraph>Элементарным примером гордости является падение Люцифера с небес.</Paragraph>

    <Question text="Исайя 14:12-15"/>

    <Paragraph>Восстание Люцифера коренится в гордости, и это привело к тому, что Бог изгнал его с неба.</Paragraph>

    <Question text="Иезекииль 28:12-17"/>

    <Paragraph>
      Драгоценные камни украшали Люцифера, и они были прекрасны, только когда отражали свет. 
      Люцифер думал, что его красота заключалась в нем самом, не понимая, что только свет Бога делал его таким прекрасным.
    </Paragraph>

    <Question text="1 Иоанна 1:5"/>

    <Paragraph>
      Ему был необходим свет, но Люцифер был обманут, полагая, что ему не нужен Бог. 
      Даже, если бы его попытка свергнуть Бога увенчалась успехом (что никогда не сможет произойти), 
      насколько бы красивым он был без Божьего света, живя в полной темноте? В этом не было бы никакой красоты! 
      Восстание и гордость начинаются с желания быть независимым от Бога. Прежде, чем Люцифер стал обманщиком, 
      его обманула его же собственная гордость. Обманщик был обманут, а гордость таким же образом может повлиять 
      на нашу с вами жизнь.
    </Paragraph>

    <Question text="Авдий 1:3"/>

    <Paragraph>
      Гордость всегда идет рука об руку с обманом. Если человек смирен, он не будет обманут, 
      а если он горд, тогда гордость заставит его верить в ложь <b>(Быт. 3:4-5)</b>. Таким образом, любой, 
      кто гордится, уже обманут, полагая, что он что-то значит без Бога. Мы видим это в культах и религиях, 
      которые обращаются к гордости, уча людей, что они сами могут найти свой путь на небо и даже когда-нибудь 
      стать богами. Это звучит здорово для нашего эгоизма, но такие корни исходят от отца всякой лжи.
    </Paragraph>

    <Question text="От Иоанна 8:44"/>

    <Paragraph>
      Люцифер получил имя Сатана, что означает “противник”. Бог сбросил его с неба и дал ему наказание, 
      которое он заслужил. Тот, кто хотел возвысить себя выше всех, в конце получил самое низкое место <b>(Мф. 23:12)</b>. 
      Веельзевул <b>(Мф. 10:25)</b>, что означает “господин мух”, будет жить вечно, окруженный личинками и червями <b>(Исайя 14:11)</b>.
    </Paragraph>

    <Paragraph>
      Интересно, что оккультисты считают, что сатана победит Иисуса в битве при Армагеддоне. 
      Даже сатана знает правду, но обман продолжает работать в жизни его последователей.
    </Paragraph>

    <Question text="Откровение 12:12"/>

    <Paragraph>
      Когда мы гордимся, мы попадаем в ловушку, расставленную для нас сатаной. Никто не застрахован от 
      саблазна возгордиться, даже царь Саул впал в него <b>(1 Цар.15:17)</b>.
    </Paragraph>

    <Title>ПРОТИВОЯДИЕ ОТ ГОРДОСТИ</Title>
    <Paragraph>Гордость оскорбляет Господа, но как мы можем угодить Ему?</Paragraph>

    <Question text="Исайя 57:15"/>

    <Paragraph>
      Лекарство от обмана гордости — это правда Божья. Мы сами и все, что у нас есть — это подарок от Бога. 
      Мы ничего из этого не заработали. Может быть мы и были верны в том, чтобы развивать Богом данные нам способности, 
      но даже это пришло от Бога. Богатые люди думают, что они самостоятельно заработали и накопили свое состояние, 
      но даже оно пришло от Бога.
    </Paragraph>

    <Question text="Второзоконие 8:18"/>

    <Paragraph>
      Господь дает нам дары, для которых у Него есть Своя цель, несмотря на то, что 
      наше эгоистичное использование может исказить их значение.
    </Paragraph>

    <Question text="1 Коринфянам 4:7"/>
    <Question text="2 Коринфянам 3:5"/>
    <Question text="Римлянам 12:3"/>

    <Paragraph>
      Если мы поймем, что все в нашей жизни приходит от Бога тогда, мы будем отдавать Ему честь, 
      а не самим себе. Мы будем благодарить, а не хвастаться. Наши способности и таланты будут служить Его славе, 
      а не нашей. Вы когда-нибудь останавливались, чтобы воздать Богу славу за все доброе, что есть в ваше жизни? 
      Какой была бы ваша жизнь без Него?
    </Paragraph>
    
    <Question text="Иеремия 9:23-24"/>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text="ПослеУрока1">
      Назовите какую-то область в вашей жизни, которая заставляет вас чувствовать себя ценными и значительными.
    </Question>
    <Question text="ПослеУрока2">
      Использовали ли вы эту область вашей жизни, чтобы привлечь внимание других к себе или к Богу?
    </Question>
    <Question text="ПослеУрока3">
      Каким образом Божье присутствие сможет больше проявляться в вашей жизни?
    </Question>

    <Title>ВОПРОСЫ В КОНЦЕ НЕДЕЛИ:</Title>
    <Question text="Проверочный1">
      Каким образом ваша гордость проявлялась на этой неделе?
    </Question>
    <Question text="Проверочный2">
      Были ли у вас искушения на этой неделе обойти или игнорировать Божьи принципы? 
      Чувствовали ли вы, что какие-то правила относятся к другим, но не к вам?
    </Question>
    <Question text="Проверочный3">
      Осознаете ли вы, что все ваши сильные стороны, таланты и способности – это дары от Бога? Объясните.
    </Question>
    <Question text="Проверочный4">
      Чем вы хвалитесь? Своими способностями или Богом?
    </Question>
  </div>

);

Week08.propTypes = {};

Week08.defaultProps = {};

export default Week08;
