import React from 'react';
import './Week10.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';

const Week10 = () => (
  <div className="Week10" data-testid="Week10">
    <LessonHeader image="https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                  week="10 НЕДЕЛЯ"
                  title="СВЯТОСТЬ"/>

      <Paragraph>
        Многие христиане зачастую не понимают, что же такое святость. 
        В Библии вы сможете найти слово «святость» 640 раз в различной форме, что говорит о 
        важности его для Господа. Давайте узнаем больше о том, что такое святость, и что значит, быть святым человеком.
      </Paragraph>

      <Title>ЧЕМ СВЯТОСТЬ НЕ ЯВЛЯЕТСЯ</Title>

      <Paragraph>
        Святость больше того, что мы можем увидеть снаружи. Многие верующие ассоциируют святость 
        с внешними проявлениями, например, как человек одевается, ходит ли он в кино или играет в карты. 
        Легко попасть в ловушку, думая, что мы можем заслужить Божье одобрение через исполнение каких-то правил 
        (Божьих или своих собственных), и таким образом, сделать себя приемлемым для Него. В результате, 
        мы чувствуем себя праведными и осуждаем других.
      </Paragraph>

      <Paragraph>
        Бог определенно хочет, чтобы наше внешнее проявление было праведным, но Он также хочет, 
        чтобы наша святая жизнь исходила из чистого сердца. Настоящая проверка святости происходит внутри: 
        какого человека Бог знает внутри нас? Чем мы занимаемся, когда нас никто не видит? О чем мы думаем? 
        Какое у нас отношение сердца?
      </Paragraph>

      <Question text="Псалом 50:6"/>

      <Title>ЧТО ТАКОЕ СВЯТОСТЬ</Title>

      <Paragraph>
        Святость означает быть отделенным для определенной цели. Это что-то чистое и ни с чем не смешаное. 
        Вот два примера из обыденной жизни, которые помогут нам лучше понять это:
      </Paragraph>

      <Paragraph>
        • Некоторые парковочные места открыты для всех автмобилей. Другие же предназначены для конкретных 
        целей и отмечены знаком, указывающим на запрет общественной стоянки. Знак определяет, кто может пользоваться 
        местами стоянки: инвалиды, персонал магазина, школьные автобусы и т. д. Когда христианин не свят, он или 
        она являются обычными, ординарными людьми. Кто-либо и что-либо имеет доступ к их мыслям, мотивам и жизни. 
        Это похоже на общественну стоянку, когда одним и тем же парковочным местом может пользоваться и мэр города 
        и мусоровоз. В таких местах нет никаких ограничений.
      </Paragraph>

      <Paragraph>
        • Чтобы зарезервировать столик в хорошем ресторане, клиенты должны заранее позвонить. 
        На этом столике ставят знак, указывающий, что он предназначен для определенных людей, и другие им пользоваться 
        уже не могут.
      </Paragraph>

      <Paragraph>
        Святость похожа на знак на стоянке или на столе. Он говорит, что мы предназначены для Бога и доступны только Ему. 
        В Ветхом Завете священники носили специальную одежду, дизайнером которой был Сам Бог. 
        Каждая часть ее имела символическое значение. На их тюрбане была золотая табличка с надписью: «Святыня Господня». 
        Это значило, что они были отделены для Него.
      </Paragraph>

      <Question text="Исход 28:36-38"/>

      <Paragraph>
        Это был символ духовной истины, что они должны быть святы, и святость должна быть видна в их жизни для всех. 
        Бог призвал священников отличаться от окружающих их людей. Интересным примером является жизнь Елисея, который 
        не был священником и не носил золотой таблички.
      </Paragraph>

      <Question text="4 Царств 4:8-9"/>

      <Paragraph>
        Эта женщина узнала в Елисее Божьего человека. Нет записей о том, что она слышала его проповеди или 
        видела, как он творит чедуса. Может быть, она слышала о его репутации и описала его, как святого. 
        Таким же образом, если мы – святые Бога, тогда другие услышат, что наша жизнь отличается и узнают 
        в нас людей Божьих. Не приобретайте себе тюрбаны с золотыми нашивками, просто живите святой жизнью. 
        Господь призвал всех христиан к святости.
      </Paragraph>

      <Question text="1 Петра 1:15-16"/>

      <Title>КАК НАМ СТАТЬ СВЯТЫМИ?</Title>

      <Paragraph>
        Святость приходит от общения с Богом, через поклонение Ему, молитву и изучение Его Слова. 
        Это не является самосовершенствованием.
      </Paragraph>

      <Question text="2 Коринфянам 3:17-18"/>

      <Paragraph>
        Когда мы видим святость Бога, осознание нашей собственной греховности поражает нас. Любой человек, о 
        котором мы читаем в Библии, встречаясь с Богом, испытывал благоговение (и ужас) в Его присутствии.
      </Paragraph>

      <Question text="Исайя 6:1-5"/>
      <Question text="Иов 40:3-4, 42:5-6"/>
      <Question text="Откровение 1:17"/>

      <Paragraph>Сравните это с отношением <b>Иова в 31</b> главе, <b>37 стих</b>.</Paragraph>

      <Question text="От Матфея 17:5-6"/>
      <Question text="От Луки 5:8"/>

      <Paragraph>
        Наша естественная реакция на проявление могущества и святости Бога — это желание бежать от Него, 
        но только в Его присутствии мы сможем ощутить милость и любовь. Мы приходим к Нему в поклонении и молитве, и 
        Божья благодать меняет нас в Его образ. Какие-то сферы нашей жизни Бог может моментально очищать от греха, в то 
        время, когда другие нуждаются в более продолжительной работе Святого Духа. Вид Его святости меняет наши сердца. 
        Зло, которое мы когда-то любили, становится отвратительным нам в Его свете.
      </Paragraph>

      <Question text="Исайя 30:22"/>

      <Paragraph>
        Мы возрастаем в святости тогда, когда отделяем свою жизнь на служение Иисусу. Любовь к Господу и желание угодить 
        Ему подталкивает нас отделиться от всего, что оскорбляет Его, и мы становимся святыми без высокомерного отношения к другим.
        Мы должны понимать насколько серезна святость. Это то, что не предлагается на выбор, но требуется от каждого христианина. 
        Когда мало говорят о святости, люди начинают думать, что она предназначена для суперпосвященных христиан, которые выходят 
        за рамки, предписаных им обязанностей. Но Писание говорит нам, что это обман. Поразмышляйте над следующим стихом:
      </Paragraph>

      <Question text="Евреям 12:14"/>

      <Paragraph>
        Причина, почему неверующие часто принимают Бога поверхностно в том, что они не понимают насколько Он другой. 
        Бог совершенно отличается от падшего человека в святости также, как и в других областях Его природы. 
        Возможно, что и многие христиане думают, что Господь такой же, как и они в их несовершенствах и неправедном 
        состоянии. Помните, что Бог призывает нас быть святыми также, как Он свят.
      </Paragraph>

      <Question text="Псалом 49:21"/>

      <Title>НАШИ СТАНДАРТЫ СВЯТОСТИ</Title>

      <Paragraph>
        ногда мы можем чувствовать себя великими христианами, потому что в чем-то трудимся больше, 
        чем другие. Даже, если бы это было правдой, это не означает, что мы святы в глазах Бога, 
        но значит только лишь то, что мы считаем себя святее того, кого судим. Замечаете ли вы, как гордость 
        и самодовольство приходят в подобные моменты? Мы также, можем верить, что мы святы, потому что отличаемся 
        от неспасенных людей. Но любая из вышеперечисленных причин не имеет силу. Писание говорит нам, что сравнивать 
        себя с другими глупо.
      </Paragraph>

      <Question text="2 Коринфянам 10:12"/>

      <Paragraph>
        Наш стандарт святости — это Господь Иисус Христос. Только в Его жизни мы найдем неизменный 
        пример святости, как внутренней, так и внешней.
      </Paragraph>

      <Question text="Евреям 13:8"/>
      <Question text="Малахия 3:6"/>

      <Paragraph>
        Стандарты добра и зла в мире постоянно меняются, обычно опускаясь все ниже и ниже, за исключением тех времен духовного 
        пробуждения, которые приносили страх Божий на неверующих.
      </Paragraph>

      <Question text="2 Тимофею 3:13"/>
      
      <Paragraph>
        Когда христиане позволяют своему уровню святости быть чем-то меньшим, чем сам Иисус, 
        они начинают меделнно скатываться вниз по спирали этого мира. Они впадают в те же грехи, 
        которые так любит этот мир. В результате медленной эрозии святости, церковь впитала в себя много греха. 
        Например, развод сегодня — это печальный факт, даже для христианского брака. Процент разводов среди христиан 
        почти такой же высокий, как и среди неверующих. Немногие церкви встают против развода настолько активно, 
        насколько этого желает Бог.
      </Paragraph>
      
      <Question text="Малахия 2:16"/>

      <Paragraph>
        Аборты по-прежнему неприемлемы для многих христиан, но при этом каждая 6 женщина в США, сделавшая аборт, 
        называла себя евангельской христианкой. И таких христиан насчитывается 150 000 каждый год. 
        А что Господь думает об этом?
      </Paragraph>

      <Question text="Притчи 6:16-19"/>

      <Paragraph>
        Некоторые христиане сегодня выступают за принятие гомсексуализма. Либеральный взгляд на подобный стиль 
        жизни — это принятие людей такими, какие они есть, не пытаясь заставить их чувствовать плохо о самих себе, 
        не осуждать их, а просто любить. Некоторые утверждают, что культура изменилась со времен Библейских дней, 
        и некоторые стихи утратили свою актуальность. Ради любви к грешнику, мы принимаем и грех. Иисус призывает 
        нас судить о ситуациях и людях справедливо, в соответствии с Божьими стандартами. Это очень отличается 
        от надменного человеческого желания критиковать или унижать другого, ради того, чтобы превознести себя. 
        Справедливый суд – это ответственность Церкви.
      </Paragraph>

      <Question text="Римлянам 1:24-28"/>
      <Question text="От Иоанна 7:24"/>
      <Question text="1 Коринфянам 5:11-6:3"/>

      <Title>СТРАТЕГИЯ САТАНЫ В УНИЧТОЖЕНИИ СВЯТОСТИ</Title>

      <Paragraph>
        Когда дьявол приближается к своей цели, он может быть очень терпеливым. Так как он знает, 
        что слишком трудно опустить христианина из святой жизни в глубокий грех за короткое время, 
        его стратегией является постоянное и медленное давление.
      </Paragraph>

      <Question text="Даниил 7:25"/>

      <Paragraph>
        Первый шаг сатаны — это отвернуть наш взгляд с Иисуса, как стандарта нашей святости. После того, как мы 
        потеряем из вида Его - наше неизменное мерило добра и зла, обман будет понемногу набирать в нас силу. Искушение, 
        подталкивая нас к маленькому шагу, начинает медленно двигать нас по направлению к миру. Как только мы сделали первый шаг, 
        следующее искушение снова зовет нас сделать еще один маленький шаг. Голос искушения шепчет нам: «Это не настолько 
        плохо, ведь ты уже сделал первый шаг». И если Бог не привлечет наше внимание к Себе, мы забудем, что там, 
        где мы находимся, уже грешим.
      </Paragraph>

      <Paragraph>
        Мало-помалу, попадая в ловушку греха, мы начинаем осозновать, что сейчас делаем то, 
        что раньше могло привести нас в ужас. Поскольку мир все глубже погрязает в грехе, 
        мы сохраняем дистанцию между собой и миром, и обманывая себя, верим, что все с нами впорядке. 
        Голос нашей совести легко игнорировать, и мы можем заглушить голос Духа Святого через твердость своего сердца.
      </Paragraph>

      <Paragraph>
        Проблема сексуального греха среди школьников и студентов- христиан, лучшее подтверждение этой сатанинской 
        стратегии. Немногие молодые люди, любящие Господа, смогли бы легко совершить прелюбодеяние, 
        но делая маленькие шаги в этом направлении, они могут оказаться в очень серьезном грехе. 
        В любой момент на этом пути, они могли бы снова взглянуть на Иисуса и осознать, как далеко они зашли. Но жаль, 
        что так много христиан не поняли, что они делают, пока их не встряхнуло, и они не вернулись к реальности после того, 
        как падение уже произошло.
      </Paragraph>

      <Question text="Ефесянам 4:27"/>
      <Question text="1 Петра 2:11"/>

      <Paragraph>
        Одна известная нехристианская комедийная актриса привела пример, каким образом мир меняет свои стандарты. 
        Если люди ахают в ответ на рассказаный ею анекдот, она отлаживает его на полгода. 
        После 6 месяцев зрители будут смеяться над этой шуткой. Она не знала, что здесь существует библейский принцип: 
        то, что люди отказываются принимать сегодня, не будет выглядить настолько плохим через 6 месяцев в глазах потерянного 
        мира. Как печально, что Церковь часто попадает в ловушку, разрушительно влияющую на жизни неспасенных людей.
      </Paragraph>
      
      <Title>ПРАКТИЧЕСКИЕ ШАГИ К СВЯТОСТИ</Title>

      <Paragraph>
        Мы должны принять близко к сердцу призыв Бога быть святыми. У нас есть ответственность изменять 
        свою жизнь по милости, которую Он дает нам.
      </Paragraph>

      <Question text="2 Коринфянам 7:1"/>
      <Question text="1 Иоанна 3:3"/>
      <Question text="Второзаконие 23:14"/>

      <Paragraph>
        Мир полон нечистот и похоти. Сексуальный грех и безнравственность одни из самых главных соблазнов, 
        с которыми встречаются молодые верующие.
      </Paragraph>
      
      <Question text="2 Петра 1:3-4"/>

      <Paragraph>
        Библия дает нам в дополнение к шагам, о которых мы узнали в учении «Победа над грехом», ключ к жизни в 
        святости. Всегда будьте осторожны в том, чему вы позволяете войти в вашу жизнь, особенно через ваши глаза. 
        Наше сердце следует за тем, что мы видим. После того, как мы впускаем что-то злое через наши глаза, мы можем 
        позволить этому укорениться в нашем сердце и разуме.
      </Paragraph>

      <Question text="Псалом 100:3"/>
      <Question text="Иов 31:1"/>
      <Question text="Иов 31:7"/>
      <Question text="Плач Иеремии 3:51"/>
      <Question text="Иеремия 4:14"/>
      <Question text="Исайя 55:7"/>

      <Paragraph>
        Когда мы сталкиваемся с соблазном совершить физический грех, скорее всего мы упадем, 
        если мы уже согрешаем им в нашем разуме. Легче что-то сделать со своим телом тогда, когда мы 
        прорепетировали это уже тысячу раз в своем разуме. Вот, почему Писание учит нас охранять наши сердца и умы.
      </Paragraph>

      <Question text="Притчи 4:23"/>

      <Paragraph>
        Вы не должны плыть по течению, следуя за миром или друзьями- христианами, которые не живут в соответствии 
        со стандартами Божьей святости. Это не легко встречать отвержение и насмешки в ответ на следование Божьими путями. 
        Иногда, вы будете чувствовать, что вы единственный, кого волнует праведность, но вы не одиноки.
      </Paragraph>

      <Question text="Римлянам 11:2-5"/>

      <Paragraph>
        Если вы сможете показывать святость со смирением, тогда Святой Дух будет обличать людей вокруг вас. 
        Только не позвольте самодовольству развиться в вашей жизни. Будьте примером подлинного христианства, 
        как для верующих, так и для неверующих людей вокруг вас.
      </Paragraph>

      <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
      <Question text="ПослеУрока1">Когда в последний раз вы пытались действовать и выглядеть, как святые?</Question>
      <Question text="ПослеУрока2">Пытались ли вы стать более святыми? Получилось?</Question>
      <Question text="ПослеУрока3">Вы когда-нибудь сравнивали себя с другими, кроме Иисуса?</Question>
      <Question text="ПослеУрока4">Что значит для вас, быть отделенными для Господа?</Question>

      <Title>ВОПРОСЫ В КОНЦЕ НЕДЕЛИ:</Title>
      <Question text="Проверочный1">Каким образом враг давил на вас отойти от стандартов Божьей святости?</Question>
      <Question text="Проверочный2">Когда в последний раз у вас было искушение позволить нечистоте войти в вашу жизнь через ваши глаза?</Question>
      <Question text="Проверочный3">В мире, который все больше наполняется злом, как вы будете поддерживать стандарты Божьей святости?</Question>
  </div>
);

Week10.propTypes = {};

Week10.defaultProps = {};

export default Week10;
