import React from 'react';
import './Week05.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';

const Week05 = () => (
  <div className="Week05" data-testid="Week05">
    <LessonHeader image="https://images.unsplash.com/photo-1500207432837-973f6b39fcdc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                  week="5 НЕДЕЛЯ"
                  title="ПОДЧИНЕНИЕ. “ЧЬЯ ЖЕ ЭТО ЖИЗНЬ, В КОНЦЕ КОНЦОВ?”"/>

    <Paragraph>
      Если мы зайдем в магазин, мы увидим там продукты разных марок. 
      В ресторане можно заказать яйца или пиццу, приготовленные разными способами. 
      В кафе можно выбрать мороженое на любой вкус. Сегодня у нас есть так много вариантов, 
      и мы можем выбрать то, что мы захотим и отказаться от того, что нам не нравится.
    </Paragraph>

    <Title>ДВА ТИПА ХРИСТИАН</Title>
    <Paragraph>
      В мире христиан бытуют две абсолютно разные точки зрения, как относиться к Богу. 
      Одно мнение, надменное и самоуверенное, основано на том, что Бог хочет сделать для нас, 
      а другое, более смиренное – что мы должны сделать для Него. Давайте посмотрим, 
      что Библия говорит об этом.
    </Paragraph>

    <Question text="Римлянам 14:7-9"/>

    <Paragraph>
      <b>В 8 стихе Павел говорит</b>, что мы «Господни». Это означает, 
      что мы – Божья собственность и, что это не Он принадлежит нам, а мы принадлежим Ему. 
      Посмотрим, что написано об этом в еще одном месте.
    </Paragraph>

    <Question text="1 Коринфянам 6:19-20"/>

    <Paragraph>
      Этот стих говорит, что Господь купил нас дорогой ценой, и наши тела и души принадлежат Ему. 
      Однако, многие христиане живут так, как будто Бог — их слуга, и действуют так, 
      как будто Он для того и существует, чтобы отвечать на их молитвы. Вера для них — это только средство, 
      чтобы осуществлять их собственные желания, а не для того, чтобы распространять Божье Царство в этом мире. 
      Некоторые проповедники могут зайти настолько далеко, говоря, что мы – маленькие боги. 
      Какое высокомерие! Мы не боги, и никогда ими не будем! Мы ничем не владеем, но Бог владеет всем!
    </Paragraph>

    <Paragraph>
      Смысл искупления заключается в том, что человеческая вина была настолько велика, 
      что невозможно было покрыть ее с помощью человеческих усилий и добрых дел. Господь знал это, но, 
      так как Он любил нас, Он взвалил всю тяжесть вины на Себя. В Ветхом Завете люди жертвовали 
      за свои грехи непорочного ягненка. Это был прообраз Иисуса, Божьего Ягненка, умершего за грехи всего человечества. 
      Бог выкупил нас Своей кровью.
    </Paragraph>

    <Question text="Левит 17:11"/>
    <Question text="Деяния 20:28"/>

    <Paragraph>
      Нам следует понять, что через кровь Иисуса мы не только очищены и прощены, 
      то также, что этой же самой кровью мы куплены, и все права на нас теперь принадлежат Богу. 
      Мы – Его собственность. Мы не можем делать то, что хотим в нашей жизни, потому что эта жизнь уже не наша, а Его!
    </Paragraph>

    <Paragraph>
      Идея того, что Бог нами владеет, не звучит очень любящей. Это правда, 
      что Он — наш Отец, а мы — Его сыновья и дочери. Бог принял нас в Свою семью. Однако, 
      когда речь заходит о Божьей воле и Его заповедях, Он — наш Правитель и Царь. 
      Мы не можем иметь одного без другого.
    </Paragraph>

    <Paragraph>
      Мы никогда не жили нашими собственными жизнями или принадлежали самим себе. 
      Перед тем, как мы пришли к Иисусу, мы были рабами греха и дьявола. Возможно, кто-то думает: 
      «Это мое дело. Никто не может мне указывать, как жить. Я живу, как я этого хочу!». Такие люди обмануты. 
      Те, кто не живут с Богом, живут с дьяволом и исполняют его волю. Прежде, все мы были рабами, и 
      нами управлял наш враг. Теперь же, у нас самый добрый и любящий Хозяин во Вселенной.
    </Paragraph>

    <Title>БИТВА МЕЖДУ ДУХОМ И ПЛОТЬЮ</Title>

    <Paragraph>
      Исход каждого конфликта между нашими желаниями и волей Бога зависит от нашего ответа на вопрос: 
      <b>«Чья же эта жизнь?»</b> Ответ на этот вопрос и заканчивает борьбу плоти и духа. 
      Имеет ли Господь абсолютное право на каждую сферу вашей жизни, или вы что-то храните для себя?
    </Paragraph>

    <Paragraph>
      Или мы живем, думая, что мы — правители нашей собственной жизни, 
      отказывая Иисусу в праве быть нашим Господином, или мы подчиняемся Ему, 
      живя жизнью подлинного христианина. Ваша жизнь все еще ваша, или она принадлежит Ему? 
      Если Бог владеет вами, тогда все в вашей жизни Его: время, таланты, имущество, деньги, 
      карьера, желания, образование, отношения и планы. Ни у кого нет такого права сказать: 
      «Извини, Господь, я знаю, чего Ты хочешь, но вот, что я хочу».
    </Paragraph>

    <Question text="От Луки 6:46"/>

    <Paragraph>
      Если ваше время принадлежит Ему, вы никогда не сможете сказать: 
      «Я слишком занят, чтобы сделать что-то для Бога». Если ваше имущество принадлежит Ему, 
      вы сможете оставить все, если Бог вас об этом попросит. Если ваши таланты принадлежат Ему, за Ним выбор, 
      как вы будете их использовать и какие результаты они принесут. Представьте себе слугу, 
      о котором рассказывал Иисус в притче (Матф. 25:20). Если бы он сказал: «Смотри, Господин! 
      Ты дал мне 5 талантов, я принес тебе еще 5. Из них я отдам тебе 2, а себе оставлю 3». 
      Что бы Господь на это ответил? «Ну ладно, Господь, а что, если Тебе – 3, а мне – 2?» Нет! 
      Это все принадлежит Богу. Наша жизнь принадлежит Богу, и, если однажды он попросит ее, 
      Он имеет на это право, ведь Он за нее заплатил, не так ли?
    </Paragraph>

    <Question text="Откровение 12:11"/>

    <Paragraph>
      Мы не сможем жить, борясь с планами и желаниями Бога. Многие христиане хотят иметь Господа всегда под рукой, 
      в случае, если придет беда, но в то же самое время, они не хотят, чтобы Он вставал на пути их планов. 
      Они так отличаются от тех христиан, которые полностью подчинили свою волю для Господа и просто желают следовать за Ним.
    </Paragraph>

    <Question text="Псалом 15:8"/>

    <Paragraph>
      Часто мы думаем о наших жизнях, как будто они все еще принадлежат нам: 
      «Я хочу это, я поеду туда, я буду делать то. Это мои цели в жизни. Это мое». Мы независимы. 
      Мы даже молимся так, как будто Бог – наш слуга, который приходит на помощь и дает нам то, чего мы просим. 
      Обратите внимание на отношение учеников в следующем отрывке из Библии. Если бы Иисус делал то, что мы просим, 
      кто тогда был бы хозяином, а кто рабом?
    </Paragraph>

    <Question text="От Марка 10:35"/>

    <Paragraph>
      Господь – это не джин из бутылке и не Санта Клаус. 
      Он существует не для того, чтобы исполнять нашу волю. Это мы существуем, 
      чтобы исполнить Его волю. Он – Господин! Мы не можем ограничивать Его, говоря: 
      “Покажи мне Свою волю настолько, насколько это вписывается в мои рамки”. 
      Все должно быть подвластно Ему в нашей жизни. Мы не можем сказать: “Нет, Господи”. 
      Мы не должны жить так, чтобы исполнять Его волю только тогда, когда она совпадает с нашей и 
      игнорировать ее, когда это трудно или неудобно для нас.
    </Paragraph>

    <Question text="От Матфея 16:24"/>

    <Paragraph>
      Если бы Бог был тираном и диктатором, тогда у нас были бы основания жаловаться на Его 
      командование и необоснованые ожидания. Но Писание и наш собственный опыт ясно показывает, 
      что Бог управляет таким образом, что это благо для всех. Он сотворил Закон, 
      защищающий людей от причинения зла себе и окружающим. Бог не создавал ни одного правила просто потому, 
      что Ему захотелось продемонстрировать Свою власть. У Бога нет таких законов, которые существуют, чтобы мучить нас, 
      запрещая и отказывая нам во всем хорошем. Послушание Божьей воле дает человеку безопасность 
      от разрушительных последствий греха. Бог нас слишком любит, чтобы позволить нам делать, то, что мы хотим.
    </Paragraph>

    <Question text="1 Иоанна 5:3"/>
    <Question text="Второзаконие 10:12-13s"/>

    <Paragraph>
      Когда мы понимаем причины Его заповедей и доброту Его повелений, 
      быть послушным становится намного легче. Однако наша упорная плоть продолжает бороться с 
      Богом за права в нашей жизни. Правда в том, что мы не можем идти на переговоры с Богом, 
      когда Он хочет одно, а мы хотим что-то другое. У нас нет права идти на компромиссы. 
      Господь Иисус Христос не заслуживает неохотного соблюдения Его Слова, но полного и немедленного послушания. 
      Если Иисус смог отдать Свою кровь и жизнь за нас, тогда все, что Он просит от нас незначительно в сравнении с тем, 
      что Он сделал. Христианин, который понимает это, рад и благодарен служить Живому Богу. 
      Когда мы ворчим о том, что нам нужно в чем- то послушаться Бога, мы позорим Его. 
      Корень наших разногласий с Богом о том, как нам жить сегодня и в будущем все тот же. 
      Мы не относимся к Богу с любовью и считаем, что наша жизнь по- прежнему принадлежит нам.
    </Paragraph>

    <Paragraph>
      Есть ли у нас ограничения для Бога в том, чего Он может от нас требовать, 
      а чего нет? Мы не обманем Бога нашим подчинением только на словах. Что будет, 
      когда Он придет, и мы будем давать Ему отчет?
    </Paragraph>

    <Title>КТО ПОБЕЖДАЕТ В БИТВЕ В ТВОЕЙ ЖИЗНИ?</Title>
    <Paragraph>
      Живете вы для себя или для Господа? В следующем библейском стихе автор говорит о своей жизни. 
      Эти слова в одном предложении объясняют все, что значит быть христианином: жить для Бога, а не для себя.
    </Paragraph>

    <Question text="2 Коринфянам 5:15"></Question>
    <Paragraph>Мы должны задать себе вопрос: живем ли мы для Него?</Paragraph>

    <Question text="2 Коринфянам 13:5"/>
    <Paragraph>
      • Принимаете ли вы важные решения, не посоветовавшись прежде с Господом? 
      Неверующим приходится принимать такие решения, основываясь на ограниченной человеческой мудрости. 
      Это страшно! Мы не можем заглядывать в будущее, чтобы увидеть результаты наших решений. Каждый раз в нашей жизни, 
      когда мы стоим перед серьезным выбором, у нас есть только один хороший вариант: молитвенно узнать волю Божью и следовать ей. 
      И тогда все получится, ведь наш Бог – добрый Бог.
    </Paragraph>
    <Paragraph>
      • Вы здесь на земле, чтобы получать пользу для себя или для Его славы? Как часто вы принимаете решения, 
      задавая вопрос: «А это будет весело?»
    </Paragraph>

    <Paragraph>
      • Являются ли целью вашей жизни комфорт, удовольствие, легкость и 
      безопасность, или для вас главное принести Богу плод?
    </Paragraph>
    <Paragraph>
      • Служение Господу – это то, что вы желаете делать, или вы чувствуете, что вы обязаны это делать?
    </Paragraph>
    <Paragraph>
      • Во время битвы между вашей волей и волей Бога, кто обычно побеждает?
    </Paragraph>  
    <Paragraph>
      • Как вы считаете, по какой причине Бог дал вам ваши способности и таланты? 
      Они для Божьей славы или для ваших собственных выгод: деньги, положение в обществе, популярность и т. д. 
      Множество известных людей: певцы, актеры, спортсмены и даже некоторые христиане злоупотребляют своими 
      талантами из эгоистичных соображений.
    </Paragraph>

    <Title>ДВЕ СТОРОНЫ ХРИСТИАНСКОЙ ЖИЗНИ</Title>

    <Paragraph>
      Это немного сложно — понять, кто мы в наших отношениях с Богом. Мы — <b>Его дети</b>, но также, мы — <b>Его слуги.</b>
    </Paragraph>

    <Question text="Римлянам 8: 15-16"/>
    <Question text="1 Коринфянам 7:22"/>

    <Paragraph>
      Мы не настолько велики в Царстве Бога, но мы дороги Ему. Наши жизни в Его распоряжении, 
      но Он ценит нас настолько сильно, что никогда не будет распоряжаться нами. Мы должны видеть нашу жизнь 
      с обеих сторон. Когда мы это делаем, становится легко подчиняться Божьему правлению, даже тогда, когда то, 
      о чем Он просит нас, кажется трудным и страшным. Когда мы знаем Его природу и характер, мы можем доверять Ему. 
      Шагать с верой в неизвестность не так страшно, когда мы знаем, что Он рядом. Как Авраам, мы знаем, что даже если 
      Он попросит нас пожертвовать самым дорогим в нашей жизни, в результате, все будет хорошо <b>(Евреям 11:17-19)</b>. 
      Мы можем достаточно доверять Богу, чтобы отдать Ему все и нас самих!
    </Paragraph>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text="ПослеУрока1">Осознавали ли вы раньше, что ваша жизнь принадлежит Богу?</Question>
    <Question text="ПослеУрока2">Напишите, что это значит лично для вас.</Question>
    <Question text="ПослеУрока3">Вы когда-нибудь пытались спорить с Богом, когда Он показывал Свою волю, и это не было то, чего вы хотели?</Question>
    <Question text="ПослеУрока4">Делали ли вы Божью работу с неохотой и жалобами.</Question>

    <Title>ВОПРОСЫ В КОНЦЕ НЕДЕЛИ:</Title>
    <Question text="Проверочный1">Принимали ли вы важные решения на этой неделе, не посоветовавшись прежде с Господом?</Question>
    <Question text="Проверочный2">Вы здесь на земле, чтобы получать пользу для себя или для Его славы? Как часто вы принимаете решения, задавая вопрос: «А это будет весело?»</Question>
    <Question text="Проверочный3">Служение Господу – это то, что вы желаете делать, или вы чувствуете, что вы обязаны это делать?</Question>
    <Question text="Проверочный4">Во время битвы между вашей волей и волей Бога, кто обычно побеждает?</Question>
    <Question text="Проверочный5">Как вы считаете, по какой причине Бог дал вам ваши способности и таланты?</Question>
  </div>
);

Week05.propTypes = {};

Week05.defaultProps = {};

export default Week05;
