import React from 'react';
import './Week02.scss';

import Question from '../lessonComponents/Question/Question';
import Paragraph from '../lessonComponents/Paragraph/Paragraph';
import Title from '../lessonComponents/Title/Title';
import LessonHeader from '../lessonComponents/LessonHeader/LessonHeader';
import Illustration from '../lessonComponents/Illustration/Illustration';

const Week02 = () => (
  <div className="Week02" data-testid="Week02">
    <LessonHeader image='https://images.unsplash.com/photo-1423766111988-c47a5ff6ed06?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2092&q=80'
    week='2 НЕДЕЛЯ'
    title='РАЗВИТИЕ МОЛИТВЕННОЙ ЖИЗНИ ЧАСТЬ 1: БЛИЗОСТЬ С БОГОМ'/>

    <Paragraph>
      Почти все молятся. Школьники молятся во время сдачи экзаменов, 
      и даже атеисты обращаются к небу, когда на них наваливаются проблемы. 
      Христиане молятся чаще других, но лишь немногим удается развить 
      сильную молитвенную жизнь. Под этим подразумевается больше, 
      чем обычные молитвы, вроде: перед едой, перед сном, когда нам нужно 
      что-то или когда мы попали в неприятности. Сильная молитвенная жизнь 
      включает в себя больше, чем 15-30 минут, проведенных с Богом каждое утро. 
      Желаете ли вы более тесной связи с Господом? Здесь вы найдете ответы! 
      В течение следующих двух недель, мы поговорим о двух важных аспектах 
      нашей молитвенной жизни: близость с Богом и духовная война.
    </Paragraph>
    
    <Title>БОГ ЖЕЛАЕТ БЛИЗОСТИ С НАМИ</Title>

    <Paragraph>
      Бог создал первых мужчину и женщину, потому что Он желал отношений с кем-то. 
      Он гулял с человеком в Эдемском саду, в совершенном раю, где не было греха 
      и страданий, пока Адам и Ева не потеряли эту привилегию <b>(Бытие 3:23-24)</b>, 
      нарушив одну из заповедей, данную им Богом (Бытие 2:16-17). Тот же самый Бог, 
      который поставил Ангела охранять вход в сад, не пропуская непослушного Адама назад, 
      позже разорвал завесу в храме, которая символизировала разделение человека и 
      Бога <b>(Матф. 25:51)</b>. И теперь, через Иисуса, мы снова можем иметь отношение с Ним!
    </Paragraph>

    <Paragraph>
      Чтобы понять желание Господа непосредственно участвовать в нашей жизни, 
      нам необходимо понимать, почему Он создал нас. Он не хотел, чтобы человек 
      был Его домашним животным, которое бы развлекало Его, иначе бы Бог ничего 
      не стал создавать после пятого дня творения. Он также не хотел, чтобы мы 
      были Его слугами, Господь уже имел бесчисленное количество ангелов, 
      которые могли бы выполнить любое поручение гораздо лучше, чем любой из нас. 
      Он желал друзей – сыновей и дочерей, которые бы выбрали сами любить и познавать Его. 
      Отец хотел иметь с нами такие же отношения, которые Он имеет с Иисусом, 
      а Иисус призвал нас быть Его друзьями.
    </Paragraph>

    <Question text='Иоанна 1:12'/>
    <Question text='Иоанна 15:15'/>
    <Question text='2 Коринфянам 6:16-7:1'/>

    <Paragraph>
      Бог предлагает нам жить вместе с Ним, но мы обычно только приходим к Нему в гости. 
      Он предлагает нам общение и дружбу, а мы выбираем формальности. 
      Он хочет отношения с нами, а мы все такие же религиозные. 
      Он призывает нас быть ближе с Ним, а мы обращаемся к Нему только когда есть в этом необходимость.
    </Paragraph>
    
    <Paragraph>
      Вы когда-нибудь задумывались, чем занимается Бог целый день? 
      Мы знаем, что делаем мы: спим, едим, учимся, ездим на работу, 
      ходим за покупками и т.д. Бог ничего подобного не делает. 
      Ему не нужно прилагать много усилий, чтобы наша планета продолжала вращаться. 
      Он не занят проблемами Ближнего Востока и стихийных бедствий. 
      Он не озабочен более серьезными проблемами других людей, чем нами. 
      В медицине существует процесс, называемый сортировка: потерпевшие, 
      находящиеся в более тяжелом состоянии, получают немедленную помощь, 
      в то время как другие ждут своей очереди. У Бога не существует духовной сортировки! 
      Мы получаем абсолютно все Его внимание, и Он достаточно велик, 
      чтобы уделять его всем шести миллиардам людей на планете в одно и то же время.
    </Paragraph>

    <Paragraph>
      В то время, как Господь смотрит на все население Земли, 
      множество из нас бесчестит Его, нарушая все заповеди, 
      оскорбляя друг друга, поклоняясь ложным богам, отвергая призыв прийти к Иисусу. 
      Та небольшая часть человечества, которая имеет живые отношения с Живым Богом, 
      как правило не близка с Ним на протяжении большей части своей повседневной жизни.
    </Paragraph>

    <Paragraph>
      Писание называет Бога «Стражем людей» <b>(Иов 7:20)</b>. Также Он — Пастырь, 
      Который заботится о своих овцах. Овцы – не очень сообразительные животные. 
      Они требуют постоянного внимания, ведь могут легко потеряться, 
      отравиться ядовитыми сорняками или окажутся съеденными волками. 
      Мы не очень от них отличаемся! Бог хранит нас день и ночь <b>(Псалом 120:3-4)</b>.
    </Paragraph>

    <Title>ШАГИ К БЛИЗОСТИ С БОГОМ</Title>

    <Paragraph>
      Как наш Небесный Отец, Бог интересуется всем, что происходит в нашей жизни. 
      Он хочет быть вовлечен во все, что мы делаем всегда и везде, 
      а не только на воскресном служении или изучении Библии. 
      Он хочет показать нам Свою защиту, обеспечение, мудрость и Его реальное присутствие 
      в каждом нашем дне. Тем не менее, мы так часто впускаем Его только в некоторые части нашей жизни, 
      а остальное закрываем от Него.
    </Paragraph>

    <Paragraph>
      Зачастую, наш день проходит без особого общения с Господом. 
      Как узнать, правда ли это в нашей жизни? Спросите сами себя, 
      как часто вы молитесь молитвами «вдогонку». Это те молитвы, которыми мы молимся перед сном, 
      чтобы догнать все в проходящем дне, о чем нужно было сказать Богу. 
      «Господи, благодарю тебя за этот день... Сегодня утром я... А потом, 
      во второй половине дня... и о, да, сегодня я... Ну, спокойной ночи, Господи, во имя Иисуса, аминь».
    </Paragraph>

    <Paragraph>
      Когда мы молимся такими молитвами, это показывает нам, 
      что Бог не был вовлечен в нашу жизнь в течение дня. 
      Он наблюдал издали за нами, потому что мы не научились приглашать и 
      включать Господа в нашу повседневную деятельность. Мы пропустили благословения, 
      а Он не достиг цели, для которой нас создавал. А ты забываешь о Господе в течение своего дня?
    </Paragraph>

    <Question text='Иеремия 2:32'/>

    <Paragraph>
      В отличие от молитв «вдогонку», настоящая молитвенная жизнь является общением 
      с Богом в течение всего дня. Наше средство общения с Ним — это молитва, 
      и Писание говорит нам молиться постоянно <b>(1 Фессалоникийцам 5:16)</b>. Очевидно, 
      что это не означает формальную, коленопреклонную, глазасомкнутую молитву день и ночь, 
      или мы не сможем ничего кроме этого сделать. Бог знает, что нам нужно есть, спать, 
      ходить в школу и иметь время для отдыха. Но Он желает, чтобы мы ходили в Духе! 
      Это означает делать сознательный выбор, чтобы жить в соответствии с нашей духовной 
      природой, как христианин, и не отдельно от Бога, следуя греховным желаниям и соблазнам 
      мира, как падший человек. Давайте рассмотрим это более подробно.
    </Paragraph>

    <Question text='Галатам 5:16-18'/>
    <Paragraph>
      Первый шаг, чтобы ходить в Духе — это простое послушание. 
      Оно является основой любящих отношений с Богом.
    </Paragraph>
    <Question text='Иоанна 14:23'/>
    <Question text='Иоанна 15:10'/>

    <Paragraph>
      Самое важное в близости с Богом — это наше отношение сердца к Нему. 
      Могли бы вы представить Иисуса, отвергающим волю Отца и восстающим против Него? 
      Ни за что! А для нас это тоже самое. Есть ли грех в вашей жизни, 
      который мешает вам быть рядом с сердцем Бога? Если да, то стоит ли это того, 
      чтобы упускать переживание величайшей любви во Вселенной и печалить сердце Того, 
      Кто так сильно любит вас? Наш ответ — это любовь и вера в нашего Господа.
    </Paragraph>

    <Question text='Иоанна 16:27'/>

    <Paragraph>
      Следующий шаг будет больше, чем просто не жить по плоти и не поддаваться искушениям и греху. 
      Нам нужно сделать осознанный выбор искать Бога и Его воли для нашей жизни, а также, 
      поставить Его приоритеты выше наших собственных и быть открытым для общения с Ним все время. 
      И мы увидим, как Святой Дух будет напоминать нам Писание, относящееся к ситуациям, 
      в которых мы находимся. Тогда мы сможем общаться с Господом в течение всего дня, 
      и Он будет говорить к нам, не обязательно слышимым голосом, но будет делиться 
      с нами Своим сердцем и чувствами. Мы мгновенно будем понимать Его одобрение или неодобрение 
      того, что мы делаем, говорим или думаем. А если мы будем внимательны к тому, 
      что Бог показывает нам, то сможем уже заранее знать, что лучше сказать или сделать. 
      Таким образом, мы быстро обнаруживаем, если сделали что-то неправильное, обидели кого-то 
      или стали причиной чьих-то проблем. Когда мы слушаем Бога перед тем, как что-то произошло, 
      а не после, мы сможем избежать многих неприятностей.
    </Paragraph>

    <Title>ТРИ УРОВНЯ ХРИСТИАНСКОЙ МОЛИТВЕННОЙ ЖИЗНИ</Title>
    <Illustration text='Диаграмма 1: Обычная молитвенная жизнь'
    image='/lessonImg/diagram1.png'/>
    <Paragraph>
      Первый уровень — это обычная христианская молитвенная жизнь, 
      когда верующий имеет короткие встречи с Богом и остальное время проводит без Него. 
      Такой человек живет на земле, но редко имеет контакт с небом. 
      Жизнь этого христианина не полностью зависит от Бога. Возможно, 
      вы слышали это мудрое изречение: «Чем больше вы независимы, тем меньше вы молитесь, 
      чем меньше вы независимы, тем больше вы молитесь». Господь хочет, 
      чтобы мы нуждались в Нем в течение всего дня.
    </Paragraph>

    <Illustration text='Диаграмма 2: «Сильная» молитвенная жизнь'
    image='/lessonImg/diagram2.png'/>
    <Paragraph>Вторым уровнем является то, что мы, как правило, считаем сильной молитвенной жизнью, 
      проводя время в Божьем присутствии в начале каждого дня и часто молиться в течение дня. 
      Этот человек берет перерыв от своей земной жизни, чтобы общаться с Богом чаще, 
      чем верующие на первом уровне. Этот уровень молитвенной жизни выше, чем первый, 
      но все же мы упускаем самое лучшее, что Бог хочет для нас.
    </Paragraph>

    <Illustration text='Диаграмма 3: То, что Бог желает для нас'
    image='/lessonImg/diagram3.png'/>
    <Paragraph>А вот, то, что Бог желает для каждого из нас — просыпаться каждое утро 
      с осознанным выбором жить в Духе и иметь общение с Господом в течение всего дня. 
      Начинается это с посвящения времени, но этим не заканчивается. 
      Хотя мы и проходим через обычную рутину жизни, но наши отношения с Богом остаются нерушимыми. 
      Умственно и физически мы можем быть полностью вовлечены в решение земных задач, 
      но духовно мы внимательны к Господу, поддерживая с Ним постоянную связь. 
      Мы обнаруживаем, что наши мысли обращены к Нему, и в ответ мы приносим свою хвалу, 
      поклонение и благодарность.
    </Paragraph>

    <Paragraph>
      На протяжении всего дня мы поддерживаем связь с Богом, и даже, 
      когда мы спим, Он может продолжать говорить к нам. Наш дух всегда бодрствует, 
      становясь чувствительным к голосу Господа. Писание сравнивает наши отношения с 
      Богом и стремление слышать Его голос с отношениями между влюбленными, 
      которые с нетерпением желают услышать друг друга и взволнованы в ожидании быть вместе.
    </Paragraph>

    <Question text='Песни Песней 5:2'/>

    <Paragraph>
      Мы можем развивать в себе духовную чувствительность к голосу Бога. 
      Одна из лучших, но не самых приятных иллюстраций этого, является сравнение людей 
      с собаками. Большинство из нас видели такую картину: собака может 
      спать, есть или гулять, но вдруг ее уши настораживаются и поднимаются вверх. 
      Каждая ее мышца напряжена, а ее слух уловил то, что никто не мог услышать. 
      И вот, она приготовилась, чтобы принять решение. Нам нужно быть точно такими же, 
      готовыми услышать то, что другие могут упустить, потому что их жизни слишком 
      поглощены этим миром. Мы должны стремиться слышать голос Бога среди обыденной 
      жизни и быть готовыми принимать незамедлительные меры. И тогда мы обязательно 
      увидим чудесное вмешательство Бога в нашу ежедневную жизнь. Мы будем слышать и 
      поступать по Божественной мудрости, избегая проблем и опасностей, видя 
      обеспечение нужд и находя ответы на свои вопросы.
    </Paragraph>
    
    <Title>НАШ ОТВЕТ НА ПРИЗЫВ К БЛИЗОСТИ</Title>

    <Paragraph>
      Как можно развить такую близость с Богом? Во-первых, разобраться, 
      на каком уровне отношений вы сейчас находитесь. Как трудно вам изменить 
      что-то в вашей молитвенной жизни? Как часто вы ходите в Духе? 
      Принимаете ли вы решения каждый день быть в тесной связи с Богом?
    </Paragraph>

    <Paragraph>
      Затем, поверьте в обещания, записанные в Библии, что Господь желает 
      общения с вами несмотря на грехи и слабости в вашей жизни. 
      Не ждите того, чтобы почувствовать Его присутствие, верьте в то, 
      что Он очищает и принимает вас через Иисуса Христа <b>(Колосс. 1:13-14)</b>. 
      Слишком часто мы думаем о молитве, как о телефонном звонке. 
      Мы тратим так много времени, чтобы просто набрать номер 
      (пытаемся сделать себя достойными, чтобы прийти к Богу) или думаем, что телефон звонит, 
      но никто не берет трубку. Вера всегда имеет доступ к Богу, и Он слушает нас! 
      Если только мы намеренно не укрываем грех в нашей жизни, мы можем быть уверены, 
      Господь всегда услышит наши молитвы!
    </Paragraph>

    <Question text='Псалом 65:18'/>
    <Question text='Ефесянам 3:12'/>
    <Question text='Евреям 4:14-16'/>
    <Question text='Евреям 10:19-22'/>

    <Paragraph>
      Мы можем терять много времени, пытаясь в молитве почувствовать присутствие Бога. 
      Если бы наши встречи с Богом были только на уровне чувств, 
      мы бы никогда не выросли в вере. Иногда Он удерживает наши чувства, 
      чтобы научить нас зависеть от Его Слова. Мы никогда не должны пытаться искать этих чувств, 
      ожидая, что они всегда будут приходить во время наших встреч с Богом. 
      Вера является более глубоким уровнем жизни.
    </Paragraph>

    <Paragraph>
      И, наконец, используйте любую возможность, чтобы познакомиться с Господом поближе. 
      Когда Он дарит вам Свою благодать, никогда не отворачивайтесь от Него. 
      У Святого Духа есть чувства, и мы можем огорчить нашего Господа не только нашими грехами, 
      но также, игнорируя Его. Если с нашими приоритетами все в порядке, 
      мы никогда не будем слишком занятыми для общения с Богом.
    </Paragraph>

    <Question text='Ефесянам 4:30'/>

    <Title>ВОПРОСЫ ПОСЛЕ УРОКА:</Title>
    <Question text='ПослеУрока1'>Переживали ли вы в своей жизни подобные близкие отношения с Богом?</Question>
    <Question text='ПослеУрока2'>Какой из графиков лучше всего описывает вашу молитвенную жизнь за последний год?</Question>
    <Question text='ПослеУрока3'>Какой из графиков молитвенной жизни вы бы хотели иметь?</Question>
    <Question text='ПослеУрока4'>Каким образом вы сможете достичь его?</Question>

    <Title>ПРОВЕРОЧНЫЕ ВОПРОСЫ:</Title>
    <Question text='Проверочный1'>Встречался ли Бог с вами на этой неделе во время ваших молитв?</Question>
    <Question text='Проверочный2'>Чувствовали ли вы Его желание участвовать в вашей повседневной жизни?</Question>
    <Question text='Проверочный3'>Если да, то каким образом?</Question>
    <Question text='Проверочный4'>Каким был ваш ответ Ему?</Question>
  </div>
);

Week02.propTypes = {};

Week02.defaultProps = {};

export default Week02;
